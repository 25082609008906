var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Donut Chart 动态环图")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  { name: '装备物资企业', value: 17 },\n  { name: '通讯保障机构', value: 1 },\n  { name: '存储仓库', value: 50 },\n  { name: '医疗卫生机构', value: 182 },\n  { name: '应急避难场所', value: 619 },\n  { name: '运输机构', value: 1 }\n]\nconst length = data.length\nlet counter = 0\nconst { Chart, Pie, Legend } = qcharts\nconst { Label } = spritejs\nconst chart = new Chart({\n  container: '#app'\n})\n\nchart.source(data, {\n  row: 'name',\n  value: 'value'\n})\n\nconst pie = new Pie({\n  startAngle: -90,\n  endAngle: 270,\n  innerRadius: 0.4,\n  radius: 0.5,\n  animation: { duration: 400 }\n})\nconst legend = new Legend()\nchart.append([pie, legend])\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'装备物资企业'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("17")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'通讯保障机构'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'存储仓库'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("50")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'医疗卫生机构'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("182")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'应急避难场所'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("619")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'运输机构'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(" }\n]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" length = data.length\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" counter = "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Chart, Pie, Legend } = qcharts\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Label } = spritejs\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\n\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("row")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'name'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'value'")
                ]),
                _vm._v("\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" pie = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Pie({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("startAngle")
                ]),
                _vm._v(": -"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("90")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("endAngle")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("270")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("innerRadius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.4")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("radius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.5")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("animation")
                ]),
                _vm._v(": { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("duration")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("400")
                ]),
                _vm._v(" }\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" legend = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Legend()\nchart.append([pie, legend])\n")
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }