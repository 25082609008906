<template><section><h2>散点图隐藏坐标轴</h2>
<block-demo tip="" source="const dataset = [
  { temperature: -2, tdp: -33, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -31, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -26, category: &#39;平均气温与露点&#39; },
  { temperature: -6, tdp: -26, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -23, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -23, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -23, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -23, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -23, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -22, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -22, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -22, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -22, category: &#39;平均气温与露点&#39; },
  { temperature: -7, tdp: -21, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -21, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -21, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -21, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -20, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -20, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -20, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: -5, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: -7, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: 6, tdp: -19, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -18, category: &#39;平均气温与露点&#39; },
  { temperature: -6, tdp: -18, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -18, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -18, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -18, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -17, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -16, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -15, category: &#39;平均气温与露点&#39; },
  { temperature: -7, tdp: -15, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -15, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -14, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -13, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -12, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -11, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: 11, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: 6, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: -10, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 7, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 6, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -9, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: -4, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: 8, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: 11, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -8, category: &#39;平均气温与露点&#39; },
  { temperature: 9, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 5, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 5, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: -3, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -7, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: -1, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 0, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 6, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 7, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 7, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -6, category: &#39;平均气温与露点&#39; },
  { temperature: -2, tdp: -5, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: -5, category: &#39;平均气温与露点&#39; },
  { temperature: 5, tdp: -5, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -5, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 5, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 9, tdp: -4, category: &#39;平均气温与露点&#39; },
  { temperature: 7, tdp: -3, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: -3, category: &#39;平均气温与露点&#39; },
  { temperature: 7, tdp: -3, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: -3, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: -3, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 6, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: -2, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 3, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: -1, category: &#39;平均气温与露点&#39; },
  { temperature: 2, tdp: 0, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 0, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 0, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: 0, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 4, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 1, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 8, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 1, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 2, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 2, category: &#39;平均气温与露点&#39; },
  { temperature: 9, tdp: 2, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 2, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 2, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 11, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 3, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 4, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 10, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 5, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 6, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 6, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 6, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 6, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 6, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 7, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 8, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 12, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 9, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 10, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 10, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 10, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 10, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 19, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 30, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 11, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 13, tdp: 12, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 16, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 14, tdp: 13, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 17, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 14, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 15, category: &#39;平均气温与露点&#39; },
  { temperature: 25, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 16, category: &#39;平均气温与露点&#39; },
  { temperature: 25, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 25, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 18, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 17, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 25, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 25, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 20, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 18, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 21, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 31, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 19, category: &#39;平均气温与露点&#39; },
  { temperature: 30, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 22, tdp: 20, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 24, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 23, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 21, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 26, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 27, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 22, category: &#39;平均气温与露点&#39; },
  { temperature: 31, tdp: 23, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 23, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 23, category: &#39;平均气温与露点&#39; },
  { temperature: 28, tdp: 23, category: &#39;平均气温与露点&#39; },
  { temperature: 31, tdp: 23, category: &#39;平均气温与露点&#39; },
  { temperature: 29, tdp: 24, category: &#39;平均气温与露点&#39; },
  { temperature: 30, tdp: 24, category: &#39;平均气温与露点&#39; }
]
let dataset2 = [...dataset]

dataset2 = dataset2.reduce((a, b) =&gt; {
  let curr = a[a.length - 1]
  if (curr &amp;&amp; curr.tdp === b.tdp) {
    curr.temperature.push(b.temperature)
  } else {
    a.push({
      temperature: [b.temperature],
      tdp: b.tdp
    })
  }
  return a
}, [])
dataset2 = dataset2.map(d =&gt; {
  d.category = &#39;气温平均露点&#39;
  d.temperature = Math.round(d.temperature.reduce((a, b) =&gt; a + b) / d.temperature.length)
  return d
})
const { Chart, Scatter, Line, Legend, Tooltip, Axis } = qcharts
const chart = new Chart({
  container: &#39;#app&#39;
})
let clientRect = { bottom: 50 }
chart.source([...dataset, ...dataset2], {
  row: &#39;category&#39;,
  value: &#39;temperature&#39;,
  text: &#39;tdp&#39;
})

const ds = chart.dataset
const d1 = ds.selectRows(&#39;平均气温与露点&#39;)
const d2 = ds.selectRows(&#39;气温平均露点&#39;)
const scatter = new Scatter({
  clientRect,
  showGuideLine: true
}).source(d1)
const line = new Line({ clientRect, layoutX: &#39;value&#39; }).source(d2)
line.style(&#39;line&#39;, function(attrs, data, i) {
  return { smooth: true, lineWidth: 3, strokeColor: &#39;#0a0&#39; }
})
line.style(&#39;point&#39;, function(attrs) {
  return { display: &#39;none&#39; }
})

const toolTip = new Tooltip({
  title: data =&gt; &#39;温度与露点：&#39;,
  formatter: data =&gt; {
    return `温度：${data.temperature}C  露点：${data.tdp}% `
  }
})
const legend = new Legend()
const axisLeft = new Axis({ orient: &#39;left&#39;, name: &#39;letf-平均气温与露点&#39;, clientRect })
  .style(&#39;axis&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;name&#39;, { translate: [-60, 0] })
const axisBottom = new Axis({ name: &#39;tdp值&#39;, clientRect }).style(&#39;name&#39;, { translate: [20, 20], fillColor: &#39;#f00&#39; })
const axisTop = new Axis({ orient: &#39;top&#39;, name: &#39;top-折线图坐标&#39;, type: &#39;category&#39; }).source(d2).style(&#39;name&#39;, { translate: [-30, -30] })

chart.append([scatter, line, axisBottom, axisLeft, toolTip, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> dataset = [
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">33</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">31</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">26</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">26</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">5</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: -<span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">2</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">4</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">8</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">9</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">30</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">14</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">15</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">25</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">16</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">25</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">25</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">17</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">25</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">25</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">18</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">31</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">19</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">30</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">27</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">22</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">31</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">31</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">23</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">29</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> },
  { <span class="hljs-attr">temperature</span>: <span class="hljs-number">30</span>, <span class="hljs-attr">tdp</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;平均气温与露点&#x27;</span> }
]
<span class="hljs-keyword">let</span> dataset2 = [...dataset]

dataset2 = dataset2.reduce(<span class="hljs-function">(<span class="hljs-params">a, b</span>) =&gt;</span> {
  <span class="hljs-keyword">let</span> curr = a[a.length - <span class="hljs-number">1</span>]
  <span class="hljs-keyword">if</span> (curr &amp;&amp; curr.tdp === b.tdp) {
    curr.temperature.push(b.temperature)
  } <span class="hljs-keyword">else</span> {
    a.push({
      <span class="hljs-attr">temperature</span>: [b.temperature],
      <span class="hljs-attr">tdp</span>: b.tdp
    })
  }
  <span class="hljs-keyword">return</span> a
}, [])
dataset2 = dataset2.map(<span class="hljs-function"><span class="hljs-params">d</span> =&gt;</span> {
  d.category = <span class="hljs-string">&#x27;气温平均露点&#x27;</span>
  d.temperature = <span class="hljs-built_in">Math</span>.round(d.temperature.reduce(<span class="hljs-function">(<span class="hljs-params">a, b</span>) =&gt;</span> a + b) / d.temperature.length)
  <span class="hljs-keyword">return</span> d
})
<span class="hljs-keyword">const</span> { Chart, Scatter, Line, Legend, Tooltip, Axis } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})
<span class="hljs-keyword">let</span> clientRect = { <span class="hljs-attr">bottom</span>: <span class="hljs-number">50</span> }
chart.source([...dataset, ...dataset2], {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;temperature&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;tdp&#x27;</span>
})

<span class="hljs-keyword">const</span> ds = chart.dataset
<span class="hljs-keyword">const</span> d1 = ds.selectRows(<span class="hljs-string">&#x27;平均气温与露点&#x27;</span>)
<span class="hljs-keyword">const</span> d2 = ds.selectRows(<span class="hljs-string">&#x27;气温平均露点&#x27;</span>)
<span class="hljs-keyword">const</span> scatter = <span class="hljs-keyword">new</span> Scatter({
  clientRect,
  <span class="hljs-attr">showGuideLine</span>: <span class="hljs-literal">true</span>
}).source(d1)
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({ clientRect, <span class="hljs-attr">layoutX</span>: <span class="hljs-string">&#x27;value&#x27;</span> }).source(d2)
line.style(<span class="hljs-string">&#x27;line&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">attrs, data, i</span>) </span>{
  <span class="hljs-keyword">return</span> { <span class="hljs-attr">smooth</span>: <span class="hljs-literal">true</span>, <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#0a0&#x27;</span> }
})
line.style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">attrs</span>) </span>{
  <span class="hljs-keyword">return</span> { <span class="hljs-attr">display</span>: <span class="hljs-string">&#x27;none&#x27;</span> }
})

<span class="hljs-keyword">const</span> toolTip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">title</span>: <span class="hljs-function"><span class="hljs-params">data</span> =&gt;</span> <span class="hljs-string">&#x27;温度与露点：&#x27;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-params">data</span> =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`温度：<span class="hljs-subst">${data.temperature}</span>C  露点：<span class="hljs-subst">${data.tdp}</span>% `</span>
  }
})
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend()
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span>, <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;letf-平均气温与露点&#x27;</span>, clientRect })
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;name&#x27;</span>, { <span class="hljs-attr">translate</span>: [-<span class="hljs-number">60</span>, <span class="hljs-number">0</span>] })
<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;tdp值&#x27;</span>, clientRect }).style(<span class="hljs-string">&#x27;name&#x27;</span>, { <span class="hljs-attr">translate</span>: [<span class="hljs-number">20</span>, <span class="hljs-number">20</span>], <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#f00&#x27;</span> })
<span class="hljs-keyword">const</span> axisTop = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;top&#x27;</span>, <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;top-折线图坐标&#x27;</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;category&#x27;</span> }).source(d2).style(<span class="hljs-string">&#x27;name&#x27;</span>, { <span class="hljs-attr">translate</span>: [-<span class="hljs-number">30</span>, -<span class="hljs-number">30</span>] })

chart.append([scatter, line, axisBottom, axisLeft, toolTip, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>