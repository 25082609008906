<template><section><h2>Double Line Chart 双 X 轴折线图</h2>
<block-demo tip="" source="const data = [
  { date: &#39;2015-1&#39;, category: &#39;2015年降水量&#39;, val: 15.2 },
  { date: &#39;2015-2&#39;, category: &#39;2015年降水量&#39;, val: 19.2 },
  { date: &#39;2015-3&#39;, category: &#39;2015年降水量&#39;, val: 11.2 },
  { date: &#39;2015-4&#39;, category: &#39;2015年降水量&#39;, val: 45.2 },
  { date: &#39;2015-5&#39;, category: &#39;2015年降水量&#39;, val: 55.2 },
  { date: &#39;2015-6&#39;, category: &#39;2015年降水量&#39;, val: 75.2 },
  { date: &#39;2015-7&#39;, category: &#39;2015年降水量&#39;, val: 95.2 },
  { date: &#39;2015-8&#39;, category: &#39;2015年降水量&#39;, val: 135.2 },
  { date: &#39;2015-9&#39;, category: &#39;2015年降水量&#39;, val: 162.2 },
  { date: &#39;2015-10&#39;, category: &#39;2015年降水量&#39;, val: 32.2 },
  { date: &#39;2015-11&#39;, category: &#39;2015年降水量&#39;, val: 32.2 },
  { date: &#39;2015-12&#39;, category: &#39;2015年降水量&#39;, val: 15.2 },

  { date: &#39;2016-1&#39;, category: &#39;2016年降水量&#39;, val: 2.2 },
  { date: &#39;2016-2&#39;, category: &#39;2016年降水量&#39;, val: 3.2 },
  { date: &#39;2016-3&#39;, category: &#39;2016年降水量&#39;, val: 5.2 },
  { date: &#39;2016-4&#39;, category: &#39;2016年降水量&#39;, val: 6.2 },
  { date: &#39;2016-5&#39;, category: &#39;2016年降水量&#39;, val: 8.2 },
  { date: &#39;2016-6&#39;, category: &#39;2016年降水量&#39;, val: 15.2 },
  { date: &#39;2016-7&#39;, category: &#39;2016年降水量&#39;, val: 25.2 },
  { date: &#39;2016-8&#39;, category: &#39;2016年降水量&#39;, val: 23.2 },
  { date: &#39;2016-9&#39;, category: &#39;2016年降水量&#39;, val: 24.2 },
  { date: &#39;2016-10&#39;, category: &#39;2016年降水量&#39;, val: 16.2 },
  { date: &#39;2016-11&#39;, category: &#39;2016年降水量&#39;, val: 12.2 },
  { date: &#39;2016-12&#39;, category: &#39;2016年降水量&#39;, val: 6.6 }
]

const { Chart, Line, Legend, Tooltip, Axis, theme } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;val&#39;,
  text: &#39;date&#39;
})
//legend与line颜色同步
theme.set({ colors: [&#39;#47A1FF&#39;, &#39;#6CD3FF&#39;] })

const ds = chart.dataset

const d1 = ds.selectRows(&#39;2015年降水量&#39;)
const line = new Line().source(d1)

line.style(&#39;line&#39;, { strokeColor: &#39;#47A1FF&#39; })
const axisBottom = new Axis({ orient: &#39;bottom&#39; }).style(&#39;grid&#39;, false).source(d1)

const d2 = ds.selectRows(&#39;2016年降水量&#39;)
const line2 = new Line().source(d2)
line2.style(&#39;line&#39;, { strokeColor: &#39;#6CD3FF&#39; })

const axisTop = new Axis({
  orient: &#39;top&#39;
})
  .style(&#39;grid&#39;, false)
  .source(d2)

const axisLeft = new Axis({ orient: &#39;left&#39; }).style(&#39;grid&#39;, { lineDash: [3, 3] })

const legend = new Legend({ align: [&#39;center&#39;, &#39;bottom&#39;] }).style(&#39;icon&#39;, { borderRadius: 10 }).style(&#39;text&#39;, { fontSize: 12 })

chart.append([line, line2, axisBottom, axisTop, axisLeft, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-1&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-2&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">19.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-3&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">11.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-4&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">45.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-5&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-6&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-7&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-8&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">135.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-9&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">162.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-10&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-11&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2015-12&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2015年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },

  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-1&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">2.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-2&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">3.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-3&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">5.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-4&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-5&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">8.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-6&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-7&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">25.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-8&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">23.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-9&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">24.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-10&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">16.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-11&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">12.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2016-12&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;2016年降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.6</span> }
]

<span class="hljs-keyword">const</span> { Chart, Line, Legend, Tooltip, Axis, theme } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;val&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})
<span class="hljs-comment">//legend与line颜色同步</span>
theme.set({ <span class="hljs-attr">colors</span>: [<span class="hljs-string">&#x27;#47A1FF&#x27;</span>, <span class="hljs-string">&#x27;#6CD3FF&#x27;</span>] })

<span class="hljs-keyword">const</span> ds = chart.dataset

<span class="hljs-keyword">const</span> d1 = ds.selectRows(<span class="hljs-string">&#x27;2015年降水量&#x27;</span>)
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line().source(d1)

line.style(<span class="hljs-string">&#x27;line&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#47A1FF&#x27;</span> })
<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;bottom&#x27;</span> }).style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>).source(d1)

<span class="hljs-keyword">const</span> d2 = ds.selectRows(<span class="hljs-string">&#x27;2016年降水量&#x27;</span>)
<span class="hljs-keyword">const</span> line2 = <span class="hljs-keyword">new</span> Line().source(d2)
line2.style(<span class="hljs-string">&#x27;line&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#6CD3FF&#x27;</span> })

<span class="hljs-keyword">const</span> axisTop = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;top&#x27;</span>
})
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)
  .source(d2)

<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span> }).style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">lineDash</span>: [<span class="hljs-number">3</span>, <span class="hljs-number">3</span>] })

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;bottom&#x27;</span>] }).style(<span class="hljs-string">&#x27;icon&#x27;</span>, { <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">10</span> }).style(<span class="hljs-string">&#x27;text&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span> })

chart.append([line, line2, axisBottom, axisTop, axisLeft, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>