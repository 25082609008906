var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("折线结束点")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  { date: '05-01', catgory: '图例一', sales: 15.2 },\n  { date: '05-02', catgory: '图例一', sales: 39.2 },\n  { date: '05-03', catgory: '图例一', sales: 31.2 },\n  { date: '05-04', catgory: '图例一', sales: 65.2 },\n  { date: '05-05', catgory: '图例一', sales: 55.2 },\n  { date: '05-06', catgory: '图例一', sales: 75.2 },\n  { date: '05-07', catgory: '图例一', sales: 95.2 },\n  { date: '05-08', catgory: '图例一' }\n]\n\nconst { Chart, Line, Legend, Tooltip, Axis } = qcharts\n\nconst chart = new Chart({\n  container: '#app'\n})\n\nchart.source(data, {\n  row: 'catgory',\n  value: 'sales',\n  text: 'date'\n})\n\nconst line = new Line({ smooth: true })\nline.style('point', function(attr, data, i, j) {\n  if (j === 6) {\n    return { size: 5, strokeColor: '#fff' }\n  }\n  return false\n})\n\nconst tooltip = new Tooltip({\n  formatter: function(data) {\n    return `${data.date} ${data.sales}`\n  }\n})\n\nconst axisBottom = new Axis().style('grid', { lineDash: [6, 6] })\n\nconst axisLeft = new Axis({ orient: 'left' }).style('scale', false).style('grid', false)\n\nconst legend = new Legend({ align: ['center', 'bottom'] }).style('icon', { borderRadius: 10 }).style('text', { fontSize: 12 })\n\nchart.append([line, tooltip, axisBottom, axisLeft, legend])\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-01'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("15.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-02'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("39.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-03'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("31.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-04'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("65.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-05'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("55.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-06'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("75.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-07'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("95.2")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-08'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("catgory")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(" }\n]\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Chart, Line, Legend, Tooltip, Axis } = qcharts\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\n\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("row")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'catgory'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'sales'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'date'")
                ]),
                _vm._v("\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" line = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Line({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("smooth")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(" })\nline.style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'point'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                    _vm._v("function")
                  ]),
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attr, data, i, j")
                  ]),
                  _vm._v(") ")
                ]),
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (j === "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6")
                ]),
                _vm._v(") {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("size")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#fff'")
                ]),
                _vm._v(" }\n  }\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v("\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" tooltip = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Tooltip({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("formatter")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                    _vm._v("function")
                  ]),
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("data")
                  ]),
                  _vm._v(") ")
                ]),
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("`"),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${data.date}")
                  ]),
                  _vm._v(" "),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${data.sales}")
                  ]),
                  _vm._v("`")
                ]),
                _vm._v("\n  }\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" axisBottom = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Axis().style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'grid'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("lineDash")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6")
                ]),
                _vm._v("] })\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" axisLeft = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Axis({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("orient")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'left'")
                ]),
                _vm._v(" }).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'scale'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(").style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'grid'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" legend = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Legend({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("align")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'center'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'bottom'")
                ]),
                _vm._v("] }).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'icon'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("borderRadius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v(" }).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'text'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fontSize")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(
                  " })\n\nchart.append([line, tooltip, axisBottom, axisLeft, legend])\n"
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }