<template><section><h2>间距堆叠条形图</h2>
<block-demo tip="" source="const data = [
  {
    data: &quot;自然灾害&quot;,
    type: &quot;一般&quot;,
    value: 30,
  },
  {
    data: &quot;自然灾害&quot;,
    type: &quot;较大&quot;,
    value: 15,
  },
  {
    data: &quot;自然灾害&quot;,
    type: &quot;重大&quot;,
    value: 4,
  },
  {
    data: &quot;自然灾害&quot;,
    type: &quot;特大&quot;,
    value: 0,
  },
  {
    data: &quot;事故灾害&quot;,
    type: &quot;一般&quot;,
    value: 36,
  },
  {
    data: &quot;事故灾害&quot;,
    type: &quot;较大&quot;,
    value: 18,
  },
  {
    data: &quot;事故灾害&quot;,
    type: &quot;重大&quot;,
    value: 3,
  },
  {
    data: &quot;事故灾害&quot;,
    type: &quot;特大&quot;,
    value: 0,
  },
  {
    data: &quot;社会安全&quot;,
    type: &quot;一般&quot;,
    value: 36,
  },
  {
    data: &quot;社会安全&quot;,
    type: &quot;较大&quot;,
    value: 12,
  },
  {
    data: &quot;社会安全&quot;,
    type: &quot;重大&quot;,
    value: 7,
  },
  {
    data: &quot;社会安全&quot;,
    type: &quot;特大&quot;,
    value: 2,
  },
  {
    data: &quot;公共卫生&quot;,
    type: &quot;一般&quot;,
    value: 28,
  },
  {
    data: &quot;公共卫生&quot;,
    type: &quot;较大&quot;,
    value: 22,
  },
  {
    data: &quot;公共卫生&quot;,
    type: &quot;重大&quot;,
    value: 4,
  },
  {
    data: &quot;公共卫生&quot;,
    type: &quot;特大&quot;,
    value: 0,
  },
]
const { Chart, Bar, Tooltip, Axis, Legend } = qcharts
const chart = new Chart({
  container: &quot;#app&quot;,
})
chart.source(data, {
  row: &quot;type&quot;,
  value: &quot;value&quot;,
  text: &quot;data&quot;,
})
const colors = [&quot;#1DC19E&quot;, &quot;#F4FA58&quot;, &quot;#FE9A2E&quot;, &quot;#FE2E2E&quot;]
const texts = [15, 9, 26, 19]
const bar = new Bar({
  stack: true,
  transpose: true,
  barWidth: 30,
  stackGap: 5,
})
  .style(&quot;pillar&quot;, (attr, data, i, j) =&gt; {
    return { fillColor: colors[j] }
  })
  .style(&quot;text&quot;, (attrs, data, i, j) =&gt; {
    if ((j + 1) % 4 !== 0) {
      return false
    }
    let size = attrs.barAttrs.size
    let points = attrs.barAttrs.points
    return {
      fillColor: &quot;#1DCE91&quot;,
      rotate: 0,
      text: texts[i],
      anchor: [0, 0.5],
      pos: [points[1][0] + 5, (points[1][1] + points[2][1]) / 2],
    }
  })
const tooltip = new Tooltip({
  formatter: (d) =&gt; ` ${d.type}: ${d.value}`,
})
const legend = new Legend({ align: [&quot;left&quot;, &quot;top&quot;] }).style(
  &quot;point&quot;,
  (attr, data, i) =&gt; {
    return {
      borderRadius: 6,
      bgcolor: colors[i],
    }
  }
)
const axisLeft = new Axis({
  orient: &quot;left&quot;,
})
  .style(&quot;axis&quot;, { strokeColor: &quot;#1DCE91&quot; })
  .style(&quot;grid&quot;, false)
  .style(&quot;scale&quot;, false)
  .style(&quot;label&quot;, { color: &quot;#1DCE91&quot; })
chart.append([bar, legend, axisLeft])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;自然灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;一般&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">30</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;自然灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;较大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">15</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;自然灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;重大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">4</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;自然灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;特大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">0</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;事故灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;一般&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">36</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;事故灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;较大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">18</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;事故灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;重大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">3</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;事故灾害&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;特大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">0</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;社会安全&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;一般&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">36</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;社会安全&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;较大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">12</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;社会安全&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;重大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">7</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;社会安全&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;特大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;公共卫生&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;一般&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">28</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;公共卫生&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;较大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">22</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;公共卫生&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;重大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">4</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;公共卫生&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;特大&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">0</span>,
  },
]
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis, Legend } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&quot;type&quot;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;value&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;data&quot;</span>,
})
<span class="hljs-keyword">const</span> colors = [<span class="hljs-string">&quot;#1DC19E&quot;</span>, <span class="hljs-string">&quot;#F4FA58&quot;</span>, <span class="hljs-string">&quot;#FE9A2E&quot;</span>, <span class="hljs-string">&quot;#FE2E2E&quot;</span>]
<span class="hljs-keyword">const</span> texts = [<span class="hljs-number">15</span>, <span class="hljs-number">9</span>, <span class="hljs-number">26</span>, <span class="hljs-number">19</span>]
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({
  <span class="hljs-attr">stack</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">transpose</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">barWidth</span>: <span class="hljs-number">30</span>,
  <span class="hljs-attr">stackGap</span>: <span class="hljs-number">5</span>,
})
  .style(<span class="hljs-string">&quot;pillar&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">fillColor</span>: colors[j] }
  })
  .style(<span class="hljs-string">&quot;text&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">if</span> ((j + <span class="hljs-number">1</span>) % <span class="hljs-number">4</span> !== <span class="hljs-number">0</span>) {
      <span class="hljs-keyword">return</span> <span class="hljs-literal">false</span>
    }
    <span class="hljs-keyword">let</span> size = attrs.barAttrs.size
    <span class="hljs-keyword">let</span> points = attrs.barAttrs.points
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span>,
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">text</span>: texts[i],
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0.5</span>],
      <span class="hljs-attr">pos</span>: [points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>] + <span class="hljs-number">5</span>, (points[<span class="hljs-number">1</span>][<span class="hljs-number">1</span>] + points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>]) / <span class="hljs-number">2</span>],
    }
  })
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">` <span class="hljs-subst">${d.type}</span>: <span class="hljs-subst">${d.value}</span>`</span>,
})
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&quot;left&quot;</span>, <span class="hljs-string">&quot;top&quot;</span>] }).style(
  <span class="hljs-string">&quot;point&quot;</span>,
  <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">6</span>,
      <span class="hljs-attr">bgcolor</span>: colors[i],
    }
  }
)
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&quot;left&quot;</span>,
})
  .style(<span class="hljs-string">&quot;axis&quot;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span> })
  .style(<span class="hljs-string">&quot;grid&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;label&quot;</span>, { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span> })
chart.append([bar, legend, axisLeft])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>