export default [
  {
    title: '',
    path: '',
    redirect: 'default'
  },
  {
    title: '玉玦图',
    path: 'default',
    component: () => import('./radialBar.md')
  },

  // {
  //   title: '更多例子',
  //   path: 'more',
  //   component: () => import('./example.md')
  // },

  // {
  //   title: 'TODO',
  //   path: 'todo',
  //   component: () => import('./todo.md')
  // }
]
