<template><section><h2>散点图隐藏坐标轴</h2>
<block-demo tip="" source="const data = [
  {
    value: 10,
    name: &#39;危化品企业&#39;
  },
  {
    value: 17,
    name: &#39;危险源(库区储/罐区)&#39;
  },
  {
    value: 13,
    name: &#39;预警&#39;
  },
  {
    value: 7,
    name: &#39;报警数&#39;
  }
]

const { Chart, Scatter, Axis } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  text: &#39;name&#39;,
  value: &#39;value&#39;
})
const scatter = new Scatter({
  axisGap: true,
  areaField: &#39;value&#39;,
  labelField: &#39;name&#39;,
  areaRange: [20, 45]
})

scatter
  .style(&#39;point&#39;, (attr, data, i) =&gt; {
    return { fillColor: &#39;rgba(48, 251, 171,0.4)&#39;, strokeColor: &#39;rgba(48, 251, 171,1)&#39; }
  })
  .style(&#39;label&#39;, (attr, data, i) =&gt; {
    return { text: data.value, fillColor: &#39;#fff&#39;, fontSize: 20 }
  })

const axis = new Axis({ type: &#39;category&#39; })
  .style(&#39;label&#39;, { width: 200, fillColor: &#39;rgba(48, 251, 171,1)&#39; })
  .style(&#39;scale&#39;, false)
  .style(&#39;axis&#39;, { strokeColor: &#39;rgba(48, 251, 171,1)&#39; })
  .style(&#39;grid&#39;, { strokeColor: &#39;rgba(48, 251, 171,1)&#39; })
const axisTop = new Axis({ orient: &#39;top&#39; })
  .style(&#39;label&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;grid&#39;, false)
  .style(&#39;axis&#39;, { lineDash: [3, 3], strokeColor: &#39;rgba(48, 251, 171,1)&#39; })
chart.append([scatter, axis, axisTop])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">value</span>: <span class="hljs-number">10</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;危化品企业&#x27;</span>
  },
  {
    <span class="hljs-attr">value</span>: <span class="hljs-number">17</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;危险源(库区储/罐区)&#x27;</span>
  },
  {
    <span class="hljs-attr">value</span>: <span class="hljs-number">13</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;预警&#x27;</span>
  },
  {
    <span class="hljs-attr">value</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;报警数&#x27;</span>
  }
]

<span class="hljs-keyword">const</span> { Chart, Scatter, Axis } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;name&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>
})
<span class="hljs-keyword">const</span> scatter = <span class="hljs-keyword">new</span> Scatter({
  <span class="hljs-attr">axisGap</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">areaField</span>: <span class="hljs-string">&#x27;value&#x27;</span>,
  <span class="hljs-attr">labelField</span>: <span class="hljs-string">&#x27;name&#x27;</span>,
  <span class="hljs-attr">areaRange</span>: [<span class="hljs-number">20</span>, <span class="hljs-number">45</span>]
})

scatter
  .style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,0.4)&#x27;</span>, <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> }
  })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">text</span>: data.value, <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span>, <span class="hljs-attr">fontSize</span>: <span class="hljs-number">20</span> }
  })

<span class="hljs-keyword">const</span> axis = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;category&#x27;</span> })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, { <span class="hljs-attr">width</span>: <span class="hljs-number">200</span>, <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> })
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> })
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> })
<span class="hljs-keyword">const</span> axisTop = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;top&#x27;</span> })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, { <span class="hljs-attr">lineDash</span>: [<span class="hljs-number">3</span>, <span class="hljs-number">3</span>], <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> })
chart.append([scatter, axis, axisTop])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>