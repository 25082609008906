<template><section><h2>自定义颜色极坐标柱状图</h2>
<block-demo tip="" source="const data = [
  {
    product: &#39;05-08&#39;,
    year: &#39;图例一&#39;,
    sales: 5
  },
  {
    product: &#39;05-08&#39;,
    year: &#39;图例二&#39;,
    sales: 15
  },

  {
    product: &#39;05-09&#39;,
    year: &#39;图例一&#39;,
    sales: 10
  },
  {
    product: &#39;05-09&#39;,
    year: &#39;图例二&#39;,
    sales: 25
  },

  {
    product: &#39;05-10&#39;,
    year: &#39;图例一&#39;,
    sales: 20
  },
  {
    product: &#39;05-10&#39;,
    year: &#39;图例二&#39;,
    sales: 30
  },
  {
    product: &#39;05-11&#39;,
    year: &#39;图例一&#39;,
    sales: 30
  },
  {
    product: &#39;05-11&#39;,
    year: &#39;图例二&#39;,
    sales: 30
  },
  {
    product: &#39;05-12&#39;,
    year: &#39;图例一&#39;,
    sales: 35
  },
  {
    product: &#39;05-12&#39;,
    year: &#39;图例二&#39;,
    sales: 35
  }
]
const { Chart, PolarBar, Tooltip, Axis, Legend } = qcharts
const chart = new Chart({
  container: &#39;#app&#39;
})
chart.source(data, {
  row: &#39;year&#39;,
  value: &#39;sales&#39;,
  text: &#39;product&#39;
})
const colors = [&#39;#FF0000&#39;, &#39;#F28F0F&#39;, &#39;#FFFF00&#39;, &#39;#00FF00&#39;, &#39;#44256F&#39;]
const bar = new PolarBar({
  stack: true,
  radius: 0.7,
  innerRadius: 0.2
}).style(&#39;pillar&#39;, (attr, data, i) =&gt; {
  if (i % 2 !== 0) {
    return { strokeColor: &#39;#fff&#39;, lineWidth: 1, fillColor: &#39;#226464&#39; }
  } else {
    return {
      strokeColor: &#39;#fff&#39;,
      lineWidth: 1,
      fillColor: colors[Math.floor(i / 2)]
    }
  }
})
const tooltip = new Tooltip({
  formatter: (d) =&gt; `${d.product} - ${d.year} - ${d.sales}`
}).style(&#39;icon&#39;, (attr, data, i) =&gt; {
  if (i % 2 !== 0) {
    return { strokeColor: &#39;#fff&#39;, lineWidth: 1, fillColor: &#39;#226464&#39; }
  } else {
    return {
      strokeColor: &#39;#fff&#39;,
      lineWidth: 1,
      fillColor: colors[Math.floor(i / 2)]
    }
  }
})
chart.append([bar, tooltip])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">5</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">15</span>
  },

  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-09&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">10</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-09&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">25</span>
  },

  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-10&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">20</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-10&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">30</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-11&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">30</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-11&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">30</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-12&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">35</span>
  },
  {
    <span class="hljs-attr">product</span>: <span class="hljs-string">&#x27;05-12&#x27;</span>,
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">35</span>
  }
]
<span class="hljs-keyword">const</span> { Chart, PolarBar, Tooltip, Axis, Legend } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;year&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;product&#x27;</span>
})
<span class="hljs-keyword">const</span> colors = [<span class="hljs-string">&#x27;#FF0000&#x27;</span>, <span class="hljs-string">&#x27;#F28F0F&#x27;</span>, <span class="hljs-string">&#x27;#FFFF00&#x27;</span>, <span class="hljs-string">&#x27;#00FF00&#x27;</span>, <span class="hljs-string">&#x27;#44256F&#x27;</span>]
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> PolarBar({
  <span class="hljs-attr">stack</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">radius</span>: <span class="hljs-number">0.7</span>,
  <span class="hljs-attr">innerRadius</span>: <span class="hljs-number">0.2</span>
}).style(<span class="hljs-string">&#x27;pillar&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
  <span class="hljs-keyword">if</span> (i % <span class="hljs-number">2</span> !== <span class="hljs-number">0</span>) {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span>, <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#226464&#x27;</span> }
  } <span class="hljs-keyword">else</span> {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span>,
      <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">1</span>,
      <span class="hljs-attr">fillColor</span>: colors[<span class="hljs-built_in">Math</span>.floor(i / <span class="hljs-number">2</span>)]
    }
  }
})
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">`<span class="hljs-subst">${d.product}</span> - <span class="hljs-subst">${d.year}</span> - <span class="hljs-subst">${d.sales}</span>`</span>
}).style(<span class="hljs-string">&#x27;icon&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
  <span class="hljs-keyword">if</span> (i % <span class="hljs-number">2</span> !== <span class="hljs-number">0</span>) {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span>, <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#226464&#x27;</span> }
  } <span class="hljs-keyword">else</span> {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span>,
      <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">1</span>,
      <span class="hljs-attr">fillColor</span>: colors[<span class="hljs-built_in">Math</span>.floor(i / <span class="hljs-number">2</span>)]
    }
  }
})
chart.append([bar, tooltip])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>