<template><section><h2>折线结束点</h2>
<block-demo tip="" source="const data = [
  { date: &#39;05-01&#39;, catgory: &#39;图例一&#39;, sales: 15.2 },
  { date: &#39;05-02&#39;, catgory: &#39;图例一&#39;, sales: 39.2 },
  { date: &#39;05-03&#39;, catgory: &#39;图例一&#39;, sales: 31.2 },
  { date: &#39;05-04&#39;, catgory: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-05&#39;, catgory: &#39;图例一&#39;, sales: 55.2 },
  { date: &#39;05-06&#39;, catgory: &#39;图例一&#39;, sales: 75.2 },
  { date: &#39;05-07&#39;, catgory: &#39;图例一&#39;, sales: 95.2 },
  { date: &#39;05-08&#39;, catgory: &#39;图例一&#39; }
]

const { Chart, Line, Legend, Tooltip, Axis } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;catgory&#39;,
  value: &#39;sales&#39;,
  text: &#39;date&#39;
})

const line = new Line({ smooth: true })
line.style(&#39;point&#39;, function(attr, data, i, j) {
  if (j === 6) {
    return { size: 5, strokeColor: &#39;#fff&#39; }
  }
  return false
})

const tooltip = new Tooltip({
  formatter: function(data) {
    return `${data.date} ${data.sales}`
  }
})

const axisBottom = new Axis().style(&#39;grid&#39;, { lineDash: [6, 6] })

const axisLeft = new Axis({ orient: &#39;left&#39; }).style(&#39;scale&#39;, false).style(&#39;grid&#39;, false)

const legend = new Legend({ align: [&#39;center&#39;, &#39;bottom&#39;] }).style(&#39;icon&#39;, { borderRadius: 10 }).style(&#39;text&#39;, { fontSize: 12 })

chart.append([line, tooltip, axisBottom, axisLeft, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">39.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">31.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&#x27;图例一&#x27;</span> }
]

<span class="hljs-keyword">const</span> { Chart, Line, Legend, Tooltip, Axis } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;catgory&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})

<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({ <span class="hljs-attr">smooth</span>: <span class="hljs-literal">true</span> })
line.style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">attr, data, i, j</span>) </span>{
  <span class="hljs-keyword">if</span> (j === <span class="hljs-number">6</span>) {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">size</span>: <span class="hljs-number">5</span>, <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span> }
  }
  <span class="hljs-keyword">return</span> <span class="hljs-literal">false</span>
})

<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">data</span>) </span>{
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${data.date}</span> <span class="hljs-subst">${data.sales}</span>`</span>
  }
})

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis().style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">lineDash</span>: [<span class="hljs-number">6</span>, <span class="hljs-number">6</span>] })

<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span> }).style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>).style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;bottom&#x27;</span>] }).style(<span class="hljs-string">&#x27;icon&#x27;</span>, { <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">10</span> }).style(<span class="hljs-string">&#x27;text&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span> })

chart.append([line, tooltip, axisBottom, axisLeft, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>