<template><section><h2>Double Line Chart 双 Y 轴折线图</h2>
<block-demo tip="" source="const data = [
  { date: &#39;05-01&#39;, category: &#39;图例一&#39;, sales: 15.2 },
  { date: &#39;05-02&#39;, category: &#39;图例一&#39;, sales: 39.2 },
  { date: &#39;05-03&#39;, category: &#39;图例一&#39;, sales: 51.2 },
  { date: &#39;05-04&#39;, category: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-05&#39;, category: &#39;图例一&#39;, sales: 55.2 },
  { date: &#39;05-06&#39;, category: &#39;图例一&#39;, sales: 75.2 },
  { date: &#39;05-07&#39;, category: &#39;图例一&#39;, sales: 85.2 },
  { date: &#39;05-08&#39;, category: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-01&#39;, category: &#39;图例二&#39;, sales: 20.2 },
  { date: &#39;05-02&#39;, category: &#39;图例二&#39;, sales: 49.2 },
  { date: &#39;05-03&#39;, category: &#39;图例二&#39;, sales: 57.2 },
  { date: &#39;05-04&#39;, category: &#39;图例二&#39;, sales: 55.2 },
  { date: &#39;05-05&#39;, category: &#39;图例二&#39;, sales: 75.2 },
  { date: &#39;05-06&#39;, category: &#39;图例二&#39;, sales: 95.2 },
  { date: &#39;05-07&#39;, category: &#39;图例二&#39;, sales: 90.2 },
  { date: &#39;05-08&#39;, category: &#39;图例二&#39;, sales: 85.2 }
]
const { Chart, Line } = qcharts
const $wrap = document.querySelector(&#39;#app&#39;)
const chart = new Chart({
  container: $wrap
})

chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;sales&#39;,
  text: &#39;date&#39;
})

let rows = chart.dataset.rows
rows.forEach(row =&gt; {
  let span = document.createElement(&#39;span&#39;)
  span.setAttribute(&#39;style&#39;, &#39;padding:10px;position:relative;z-index:1&#39;)
  span.innerHTML = row.name
  $wrap.append(span)
  span.addEventListener(
    &#39;click&#39;,
    _ =&gt; {
      row.state = row.state === &#39;disabled&#39; ? &#39;default&#39; : &#39;disabled&#39;
    },
    false
  )
})
const line = new Line({ splitNumber: 5 })
chart.dataset.on(&#39;change&#39;, function(res) {
  console.log(&#39;dataset&#39;, res)
})

chart.append([line])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">39.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">51.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">85.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">20.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">49.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">57.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">90.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">85.2</span> }
]
<span class="hljs-keyword">const</span> { Chart, Line } = qcharts
<span class="hljs-keyword">const</span> $wrap = <span class="hljs-built_in">document</span>.querySelector(<span class="hljs-string">&#x27;#app&#x27;</span>)
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: $wrap
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})

<span class="hljs-keyword">let</span> rows = chart.dataset.rows
rows.forEach(<span class="hljs-function"><span class="hljs-params">row</span> =&gt;</span> {
  <span class="hljs-keyword">let</span> span = <span class="hljs-built_in">document</span>.createElement(<span class="hljs-string">&#x27;span&#x27;</span>)
  span.setAttribute(<span class="hljs-string">&#x27;style&#x27;</span>, <span class="hljs-string">&#x27;padding:10px;position:relative;z-index:1&#x27;</span>)
  span.innerHTML = row.name
  $wrap.append(span)
  span.addEventListener(
    <span class="hljs-string">&#x27;click&#x27;</span>,
    <span class="hljs-function"><span class="hljs-params">_</span> =&gt;</span> {
      row.state = row.state === <span class="hljs-string">&#x27;disabled&#x27;</span> ? <span class="hljs-string">&#x27;default&#x27;</span> : <span class="hljs-string">&#x27;disabled&#x27;</span>
    },
    <span class="hljs-literal">false</span>
  )
})
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({ <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span> })
chart.dataset.on(<span class="hljs-string">&#x27;change&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">res</span>) </span>{
  <span class="hljs-built_in">console</span>.log(<span class="hljs-string">&#x27;dataset&#x27;</span>, res)
})

chart.append([line])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>