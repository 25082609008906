<template><section><h3>修改雷达图点形状</h3>
<block-demo tip="" source="const data = [
  { label: &#39;分类一&#39;, category: &#39;类别一&#39;, value: 19 },
  { label: &#39;分类二&#39;, category: &#39;类别一&#39;, value: 74 },
  { label: &#39;分类三&#39;, category: &#39;类别一&#39;, value: 40 },
  { label: &#39;分类四&#39;, category: &#39;类别一&#39;, value: 46 },
  { label: &#39;分类五&#39;, category: &#39;类别一&#39;, value: 30 },
  { label: &#39;分类六&#39;, category: &#39;类别一&#39;, value: 62 },

  { label: &#39;分类一&#39;, category: &#39;类别二&#39;, value: 69 },
  { label: &#39;分类二&#39;, category: &#39;类别二&#39;, value: 14 },
  { label: &#39;分类三&#39;, category: &#39;类别二&#39;, value: 70 },
  { label: &#39;分类四&#39;, category: &#39;类别二&#39;, value: 26 },
  { label: &#39;分类五&#39;, category: &#39;类别二&#39;, value: 50 },
  { label: &#39;分类六&#39;, category: &#39;类别二&#39;, value: 52 }
]

const { Chart, Radar, Tooltip, Legend } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})
chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;value&#39;,
  text: &#39;label&#39;
})

const radar = new Radar()

radar.style(&#39;point&#39;, (d, data, i) =&gt; {
  if (data.category === &#39;类别一&#39;) {
    return { pointType: &#39;rect&#39; }
  }
})

const legend = new Legend({ align: [&#39;center&#39;, &#39;bottom&#39;] })
chart.append([radar, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类一&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">19</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类二&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">74</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类三&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">40</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类四&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">46</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类五&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">30</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类六&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别一&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">62</span> },

  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类一&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">69</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类二&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">14</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类三&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">70</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类四&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">26</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类五&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">50</span> },
  { <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;分类六&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;类别二&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">52</span> }
]

<span class="hljs-keyword">const</span> { Chart, Radar, Tooltip, Legend } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;label&#x27;</span>
})

<span class="hljs-keyword">const</span> radar = <span class="hljs-keyword">new</span> Radar()

radar.style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">d, data, i</span>) =&gt;</span> {
  <span class="hljs-keyword">if</span> (data.category === <span class="hljs-string">&#x27;类别一&#x27;</span>) {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">pointType</span>: <span class="hljs-string">&#x27;rect&#x27;</span> }
  }
})

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;bottom&#x27;</span>] })
chart.append([radar, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>