var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c(
      "nav",
      { staticClass: "navbar" },
      [
        _c("router-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
          _c("img", {
            attrs: {
              src: "https://p2.ssl.qhimg.com/t01aec2b6fbfbb68e03.png",
              height: "40",
              alt: "qchart"
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "navbar-toggler", on: { click: _vm.toggleShowMenu } },
          [
            _c("span", [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 30 30",
                    width: "30",
                    height: "30",
                    focusable: "false"
                  }
                },
                [
                  _c("title", [_vm._v("Menu")]),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      stroke: "currentColor",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-miterlimit": "10",
                      d: "M4 7h22M4 15h22M4 23h22"
                    }
                  })
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.showMenu ? "is-show" : "" },
          [
            _c("Search"),
            _vm._v(" "),
            _c("ul", { staticClass: "navbar-nav" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("router-link", { attrs: { to: "/home" } }, [
                    _vm._v("首页")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("router-link", { attrs: { to: "/doc" } }, [
                    _vm._v("API文档")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("router-link", { attrs: { to: "/demo" } }, [
                    _vm._v("图表示例")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("router-link", { attrs: { to: "/more" } }, [
                    _vm._v("更多图例")
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }