<template><section><h2>隐藏坐标轴</h2>
<block-demo tip="" source="const data = [
  { value: 2200, label: &quot;05-01&quot; },
  { value: 6000, label: &quot;05-02&quot; },
  { value: 5600, label: &quot;05-03&quot; },
  { value: 8500, label: &quot;05-04&quot; },
  { value: 13600, label: &quot;05-05&quot; },
  { value: 10800, label: &quot;05-06&quot; },
  { value: 6400, label: &quot;05-07&quot; },
  { value: 3500, label: &quot;05-08&quot; },
]
const { Chart, Bar, Tooltip, Axis } = qcharts
const chart = new Chart({
  container: &quot;#app&quot;,
})
chart.source(data, {
  value: &quot;value&quot;,
  text: &quot;label&quot;,
})
let bool = true
const bar = new Bar({})
  .style(&quot;pillar&quot;, { bgcolor: &quot;#47A1FF&quot; })
  .style(&quot;text&quot;, (attrs, data, i, j) =&gt; {
    let size = attrs.barAttrs.size
    let points = attrs.barAttrs.points
    let str = data.value + &quot;&quot;

    let result = str
      .split(&quot;&quot;)
      .reverse()
      .reduce((prev, next, index) =&gt; {
        return (index % 3 ? next : next + &quot;,&quot;) + prev
      })
    return {
      fillColor: &quot;#2AAAFF&quot;,
      rotate: 0,
      text: result,
      anchor: [0.5, 1],
      pos: [(points[0][0] + points[1][0]) / 2, points[0][1]],
      padding: 0,
    }
  })
const tooltip = new Tooltip({
  formatter: (d) =&gt; `${d.label}: ${d.value}`,
})

const axisBottom = new Axis()
  .style(&quot;axis&quot;, false)
  .style(&quot;scale&quot;, false)
  .style(&quot;label&quot;, function(attrs, data, i) {
    return {
      rotate: 45,
      translate: [-10, 0],
      anchor: [0, 0],
    }
  })

chart.append([bar, tooltip, axisBottom])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">value</span>: <span class="hljs-number">2200</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-01&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">6000</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-02&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">5600</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-03&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">8500</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-04&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">13600</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-05&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">10800</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-06&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">6400</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-07&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">3500</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;05-08&quot;</span> },
]
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})
chart.source(data, {
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;value&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;label&quot;</span>,
})
<span class="hljs-keyword">let</span> bool = <span class="hljs-literal">true</span>
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({})
  .style(<span class="hljs-string">&quot;pillar&quot;</span>, { <span class="hljs-attr">bgcolor</span>: <span class="hljs-string">&quot;#47A1FF&quot;</span> })
  .style(<span class="hljs-string">&quot;text&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> size = attrs.barAttrs.size
    <span class="hljs-keyword">let</span> points = attrs.barAttrs.points
    <span class="hljs-keyword">let</span> str = data.value + <span class="hljs-string">&quot;&quot;</span>

    <span class="hljs-keyword">let</span> result = str
      .split(<span class="hljs-string">&quot;&quot;</span>)
      .reverse()
      .reduce(<span class="hljs-function">(<span class="hljs-params">prev, next, index</span>) =&gt;</span> {
        <span class="hljs-keyword">return</span> (index % <span class="hljs-number">3</span> ? next : next + <span class="hljs-string">&quot;,&quot;</span>) + prev
      })
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&quot;#2AAAFF&quot;</span>,
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">text</span>: result,
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0.5</span>, <span class="hljs-number">1</span>],
      <span class="hljs-attr">pos</span>: [(points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] + points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>]) / <span class="hljs-number">2</span>, points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>]],
      <span class="hljs-attr">padding</span>: <span class="hljs-number">0</span>,
    }
  })
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">`<span class="hljs-subst">${d.label}</span>: <span class="hljs-subst">${d.value}</span>`</span>,
})

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis()
  .style(<span class="hljs-string">&quot;axis&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;label&quot;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">attrs, data, i</span>) </span>{
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">45</span>,
      <span class="hljs-attr">translate</span>: [-<span class="hljs-number">10</span>, <span class="hljs-number">0</span>],
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>],
    }
  })

chart.append([bar, tooltip, axisBottom])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>