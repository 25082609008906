<template><section><h2>Double Line Chart 双 Y 轴折线图</h2>
<block-demo tip="" source="const data = [
  { date: &#39;1月&#39;, category: &#39;降水量&#39;, val: 15.2 },
  { date: &#39;2月&#39;, category: &#39;降水量&#39;, val: 19.2 },
  { date: &#39;3月&#39;, category: &#39;降水量&#39;, val: 11.2 },
  { date: &#39;4月&#39;, category: &#39;降水量&#39;, val: 45.2 },
  { date: &#39;5月&#39;, category: &#39;降水量&#39;, val: 55.2 },
  { date: &#39;6月&#39;, category: &#39;降水量&#39;, val: 75.2 },
  { date: &#39;7月&#39;, category: &#39;降水量&#39;, val: 95.2 },
  { date: &#39;8月&#39;, category: &#39;降水量&#39;, val: 135.2 },
  { date: &#39;9月&#39;, category: &#39;降水量&#39;, val: 162.2 },
  { date: &#39;10月&#39;, category: &#39;降水量&#39;, val: 32.2 },
  { date: &#39;11月&#39;, category: &#39;降水量&#39;, val: 32.2 },
  { date: &#39;12月&#39;, category: &#39;降水量&#39;, val: 15.2 },

  { date: &#39;1月&#39;, category: &#39;气温&#39;, val: 2.2 },
  { date: &#39;2月&#39;, category: &#39;气温&#39;, val: 3.2 },
  { date: &#39;3月&#39;, category: &#39;气温&#39;, val: 5.2 },
  { date: &#39;4月&#39;, category: &#39;气温&#39;, val: 6.2 },
  { date: &#39;5月&#39;, category: &#39;气温&#39;, val: 8.2 },
  { date: &#39;6月&#39;, category: &#39;气温&#39;, val: 15.2 },
  { date: &#39;7月&#39;, category: &#39;气温&#39;, val: 25.2 },
  { date: &#39;8月&#39;, category: &#39;气温&#39;, val: 23.2 },
  { date: &#39;9月&#39;, category: &#39;气温&#39;, val: 24.2 },
  { date: &#39;10月&#39;, category: &#39;气温&#39;, val: 16.2 },
  { date: &#39;11月&#39;, category: &#39;气温&#39;, val: 12.2 },
  { date: &#39;12月&#39;, category: &#39;气温&#39;, val: 6.6 }
]

const { Chart, Line, Legend, Tooltip, Axis, theme } = qcharts
const colors = theme.colors
const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;val&#39;,
  text: &#39;date&#39;
})

const ds = chart.dataset

const d1 = ds.selectRows(&#39;降水量&#39;)
const line = new Line({
  splitNumber: 5,
  guideline: false
})
  .source(d1)
  .style(&#39;point&#39;, { strokeColor: &#39;#fff&#39; })
  .style(&#39;line&#39;, { strokeColor: colors[0] })
const axisLeft = new Axis({
  orient: &#39;left&#39;,
  splitNumber: 5,
  name: &#39;降水量（毫升）&#39;,
  formatter: val =&gt; {
    return `${val} ml`
  }
})
  .style(&#39;axis&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;name&#39;, { fontSize: 15 })
  .style(&#39;grid&#39;, { lineDash: [3, 3] })
  .source(d1)

const d2 = ds.selectRows(&#39;气温&#39;)
const line2 = new Line({
  splitNumber: 5
})
  .source(d2)
  .style(&#39;line&#39;, { strokeColor: colors[1] })
  .style(&#39;point&#39;, { fillColor: colors[1] })

const axisRight = new Axis({
  splitNumber: 5,
  orient: &#39;right&#39;,
  name: &#39;温度（摄氏度）&#39;,
  formatter: val =&gt; {
    return `${val} °C`
  }
})
  .style(&#39;axis&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;name&#39;, { fontSize: 12, translate: [10, 0] })
  .source(d2)

const axisBottom = new Axis().style(&#39;scale&#39;, true).style(&#39;grid&#39;, false)

const legend = new Legend({ align: [&#39;center&#39;, &#39;bottom&#39;] }).style(&#39;icon&#39;, { borderRadius: 10 }).style(&#39;text&#39;, { fontSize: 12 })

const tooltip = new Tooltip({
  title: t =&gt; t[0].date,
  formatter: function(d) {
    const date = d.date
    const curData = data.filter(item =&gt; item.date === date)
    return `气温:${curData[1].val}°C  降水:${curData[0].val}ml`
  }
})

chart.append([line, line2, axisBottom, axisLeft, axisRight, legend, tooltip])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;1月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">19.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;3月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">11.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;4月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">45.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;5月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;6月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;7月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;8月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">135.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;9月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">162.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;10月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;11月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;12月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },

  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;1月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">2.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">3.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;3月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">5.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;4月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;5月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">8.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;6月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;7月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">25.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;8月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">23.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;9月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">24.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;10月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">16.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;11月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">12.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;12月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.6</span> }
]

<span class="hljs-keyword">const</span> { Chart, Line, Legend, Tooltip, Axis, theme } = qcharts
<span class="hljs-keyword">const</span> colors = theme.colors
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;val&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})

<span class="hljs-keyword">const</span> ds = chart.dataset

<span class="hljs-keyword">const</span> d1 = ds.selectRows(<span class="hljs-string">&#x27;降水量&#x27;</span>)
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({
  <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span>,
  <span class="hljs-attr">guideline</span>: <span class="hljs-literal">false</span>
})
  .source(d1)
  .style(<span class="hljs-string">&#x27;point&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span> })
  .style(<span class="hljs-string">&#x27;line&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: colors[<span class="hljs-number">0</span>] })
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span>,
  <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span>,
  <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;降水量（毫升）&#x27;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-params">val</span> =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${val}</span> ml`</span>
  }
})
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;name&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">15</span> })
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">lineDash</span>: [<span class="hljs-number">3</span>, <span class="hljs-number">3</span>] })
  .source(d1)

<span class="hljs-keyword">const</span> d2 = ds.selectRows(<span class="hljs-string">&#x27;气温&#x27;</span>)
<span class="hljs-keyword">const</span> line2 = <span class="hljs-keyword">new</span> Line({
  <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span>
})
  .source(d2)
  .style(<span class="hljs-string">&#x27;line&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: colors[<span class="hljs-number">1</span>] })
  .style(<span class="hljs-string">&#x27;point&#x27;</span>, { <span class="hljs-attr">fillColor</span>: colors[<span class="hljs-number">1</span>] })

<span class="hljs-keyword">const</span> axisRight = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span>,
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;right&#x27;</span>,
  <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;温度（摄氏度）&#x27;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-params">val</span> =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${val}</span> °C`</span>
  }
})
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;name&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span>, <span class="hljs-attr">translate</span>: [<span class="hljs-number">10</span>, <span class="hljs-number">0</span>] })
  .source(d2)

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis().style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">true</span>).style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;bottom&#x27;</span>] }).style(<span class="hljs-string">&#x27;icon&#x27;</span>, { <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">10</span> }).style(<span class="hljs-string">&#x27;text&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span> })

<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">title</span>: <span class="hljs-function"><span class="hljs-params">t</span> =&gt;</span> t[<span class="hljs-number">0</span>].date,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">d</span>) </span>{
    <span class="hljs-keyword">const</span> date = d.date
    <span class="hljs-keyword">const</span> curData = data.filter(<span class="hljs-function"><span class="hljs-params">item</span> =&gt;</span> item.date === date)
    <span class="hljs-keyword">return</span> <span class="hljs-string">`气温:<span class="hljs-subst">${curData[<span class="hljs-number">1</span>].val}</span>°C  降水:<span class="hljs-subst">${curData[<span class="hljs-number">0</span>].val}</span>ml`</span>
  }
})

chart.append([line, line2, axisBottom, axisLeft, axisRight, legend, tooltip])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>