var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("aside", { class: { "is-show": _vm.showMenu, "side-menu": 1 } }, [
      _c("nav", [
        _c(
          "ul",
          [
            _vm._l(_vm.routes, function(item, i) {
              return [
                !item.redirect && !item.hidden
                  ? _c("li", { key: i }, [
                      item.children
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "menu menu-title",
                                  on: {
                                    click: function($event) {
                                      return _vm.menuTitleClick(item.path)
                                    }
                                  }
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.title))]),
                                  _vm._v(" "),
                                  _c("img", {
                                    class: {
                                      transfrom0: item.path === _vm.showPath
                                    },
                                    attrs: { src: require("./down.svg") }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "transition",
                                { attrs: { name: "collapse" } },
                                [
                                  _c(
                                    "ul",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.path === _vm.showPath,
                                          expression: "item.path === showPath"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._l(item.children, function(
                                        subItem,
                                        index
                                      ) {
                                        return [
                                          !subItem.redirect && !subItem.hidden
                                            ? _c(
                                                "li",
                                                { key: index },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "menu menu-item",
                                                      attrs: {
                                                        to:
                                                          "/" +
                                                          _vm.parentRoutePath +
                                                          "/" +
                                                          item.path +
                                                          "/" +
                                                          subItem.path
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subItem.title)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "menu menu-title",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.parentRoutePath +
                                      "/" +
                                      item.path
                                  }
                                },
                                [_vm._v(_vm._s(item.title))]
                              )
                            ],
                            1
                          )
                    ])
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn", on: { click: _vm.toggleShowMenu } }, [
        _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 1024 1024",
              "data-icon": "bars",
              width: "1em",
              height: "1em",
              fill: "currentColor",
              "aria-hidden": "true"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"
              }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "article",
      { staticClass: "markdown-article" },
      [
        _c("router-view", { staticClass: "markdown-body" }),
        _vm._v(" "),
        _c("Top")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }