<template><section><h2>散点图隐藏坐标轴</h2>
<block-demo tip="" source="const data = [
  {
    x: 10,
    y: 10,
    z: 8,
    name: &#39;盐井&#39;
  },
  {
    x: 15,
    y: 17,
    z: 5,
    name: &#39;云门&#39;
  },
  {
    x: 25,
    y: 13,
    z: 7,
    name: &#39;钓鱼城&#39;
  },
  {
    x: 38,
    y: 7,
    z: 12,
    name: &#39;合阳城&#39;
  },
  {
    x: 38,
    y: 17,
    z: 5,
    name: &#39;大石&#39;
  },

  {
    x: 50,
    y: 17,
    z: 7,
    name: &#39;钓鱼城&#39;
  },
  {
    x: 55,
    y: 8,
    z: 7,
    name: &#39;草街&#39;
  },
  {
    x: 65,
    y: 13,
    z: 7,
    name: &#39;云门&#39;
  }
]

const { Chart, Scatter, Legend, Tooltip } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  text: &#39;x&#39;,
  value: &#39;y&#39;
})
const scatter = new Scatter({
  areaField: &#39;z&#39;,
  labelField: &#39;name&#39;,
  areaRange: [20, 45],
  layoutWay: {
    x: { min: 0, max: 80 },
    y: { min: 0, max: 25 }
  }
})

scatter
  .style(&#39;point&#39;, (attr, data, i) =&gt; {
    const colorTable = {
      盐井: &#39;#1B2EA0&#39;,
      云门: &#39;#06373c&#39;,
      钓鱼城: &#39;#1B77A0&#39;,
      合阳城: &#39;#1B5EA0&#39;,
      大石: &#39;#13266b&#39;,
      草街: &#39;#20602A&#39;
    }
    const { name, x } = data
    let fillColor = colorTable[name]
    if (x === 65) {
      fillColor = &#39;#13516d&#39;
    }
    return { fillColor, strokeColor: &#39;transparent&#39; }
  })
  .style(&#39;label&#39;, (attr, data, i) =&gt; {
    let fillColor = &#39;#fff&#39;
    let fontSize = 10
    const { name, x } = data
    if (name === &#39;云门&#39; &amp;&amp; x !== 65) {
      fillColor = &#39;#757474&#39;
    } else if (name === &#39;大石&#39;) {
      fillColor = &#39;#adabab&#39;
    } else if (x === 65) {
      fillColor = &#39;#a0a0a0&#39;
    }
    if (name === &#39;盐井&#39; || name === &#39;草街&#39;) {
      fontSize = 14
    } else if (name === &#39;合阳城&#39;) {
      fontSize = 18
    }
    return { fillColor, fontSize }
  })

const tooltip = new Tooltip({
  formatter: (data) =&gt; {
    return `${data.name} x：${data.x} y：${data.y} `
  }
})

chart.append([scatter, tooltip])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">10</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">10</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">8</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;盐井&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">15</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">17</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">5</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;云门&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">25</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">13</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;钓鱼城&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">38</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">12</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;合阳城&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">38</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">17</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">5</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;大石&#x27;</span>
  },

  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">50</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">17</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;钓鱼城&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">55</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">8</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;草街&#x27;</span>
  },
  {
    <span class="hljs-attr">x</span>: <span class="hljs-number">65</span>,
    <span class="hljs-attr">y</span>: <span class="hljs-number">13</span>,
    <span class="hljs-attr">z</span>: <span class="hljs-number">7</span>,
    <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;云门&#x27;</span>
  }
]

<span class="hljs-keyword">const</span> { Chart, Scatter, Legend, Tooltip } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;x&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;y&#x27;</span>
})
<span class="hljs-keyword">const</span> scatter = <span class="hljs-keyword">new</span> Scatter({
  <span class="hljs-attr">areaField</span>: <span class="hljs-string">&#x27;z&#x27;</span>,
  <span class="hljs-attr">labelField</span>: <span class="hljs-string">&#x27;name&#x27;</span>,
  <span class="hljs-attr">areaRange</span>: [<span class="hljs-number">20</span>, <span class="hljs-number">45</span>],
  <span class="hljs-attr">layoutWay</span>: {
    <span class="hljs-attr">x</span>: { <span class="hljs-attr">min</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">max</span>: <span class="hljs-number">80</span> },
    <span class="hljs-attr">y</span>: { <span class="hljs-attr">min</span>: <span class="hljs-number">0</span>, <span class="hljs-attr">max</span>: <span class="hljs-number">25</span> }
  }
})

scatter
  .style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">const</span> colorTable = {
      盐井: <span class="hljs-string">&#x27;#1B2EA0&#x27;</span>,
      云门: <span class="hljs-string">&#x27;#06373c&#x27;</span>,
      钓鱼城: <span class="hljs-string">&#x27;#1B77A0&#x27;</span>,
      合阳城: <span class="hljs-string">&#x27;#1B5EA0&#x27;</span>,
      大石: <span class="hljs-string">&#x27;#13266b&#x27;</span>,
      草街: <span class="hljs-string">&#x27;#20602A&#x27;</span>
    }
    <span class="hljs-keyword">const</span> { name, x } = data
    <span class="hljs-keyword">let</span> fillColor = colorTable[name]
    <span class="hljs-keyword">if</span> (x === <span class="hljs-number">65</span>) {
      fillColor = <span class="hljs-string">&#x27;#13516d&#x27;</span>
    }
    <span class="hljs-keyword">return</span> { fillColor, <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;transparent&#x27;</span> }
  })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> fillColor = <span class="hljs-string">&#x27;#fff&#x27;</span>
    <span class="hljs-keyword">let</span> fontSize = <span class="hljs-number">10</span>
    <span class="hljs-keyword">const</span> { name, x } = data
    <span class="hljs-keyword">if</span> (name === <span class="hljs-string">&#x27;云门&#x27;</span> &amp;&amp; x !== <span class="hljs-number">65</span>) {
      fillColor = <span class="hljs-string">&#x27;#757474&#x27;</span>
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (name === <span class="hljs-string">&#x27;大石&#x27;</span>) {
      fillColor = <span class="hljs-string">&#x27;#adabab&#x27;</span>
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (x === <span class="hljs-number">65</span>) {
      fillColor = <span class="hljs-string">&#x27;#a0a0a0&#x27;</span>
    }
    <span class="hljs-keyword">if</span> (name === <span class="hljs-string">&#x27;盐井&#x27;</span> || name === <span class="hljs-string">&#x27;草街&#x27;</span>) {
      fontSize = <span class="hljs-number">14</span>
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (name === <span class="hljs-string">&#x27;合阳城&#x27;</span>) {
      fontSize = <span class="hljs-number">18</span>
    }
    <span class="hljs-keyword">return</span> { fillColor, fontSize }
  })

<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">data</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${data.name}</span> x：<span class="hljs-subst">${data.x}</span> y：<span class="hljs-subst">${data.y}</span> `</span>
  }
})

chart.append([scatter, tooltip])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>