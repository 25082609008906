<template><section><h2>Donut Chart 动态环图</h2>
<block-demo tip="" source="const data = [
  { name: &#39;装备物资企业&#39;, value: 17 },
  { name: &#39;通讯保障机构&#39;, value: 1 },
  { name: &#39;存储仓库&#39;, value: 50 },
  { name: &#39;医疗卫生机构&#39;, value: 182 },
  { name: &#39;应急避难场所&#39;, value: 619 },
  { name: &#39;运输机构&#39;, value: 1 }
]
const length = data.length
let counter = 0
const { Chart, Pie, Legend } = qcharts
const { Label } = spritejs
const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;name&#39;,
  value: &#39;value&#39;
})

const pie = new Pie({
  startAngle: -90,
  endAngle: 270,
  innerRadius: 0.4,
  radius: 0.5,
  animation: { duration: 400 }
})
const legend = new Legend()
chart.append([pie, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;装备物资企业&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">17</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;通讯保障机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">1</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;存储仓库&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">50</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;医疗卫生机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">182</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;应急避难场所&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">619</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;运输机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">1</span> }
]
<span class="hljs-keyword">const</span> length = data.length
<span class="hljs-keyword">let</span> counter = <span class="hljs-number">0</span>
<span class="hljs-keyword">const</span> { Chart, Pie, Legend } = qcharts
<span class="hljs-keyword">const</span> { Label } = spritejs
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;name&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>
})

<span class="hljs-keyword">const</span> pie = <span class="hljs-keyword">new</span> Pie({
  <span class="hljs-attr">startAngle</span>: -<span class="hljs-number">90</span>,
  <span class="hljs-attr">endAngle</span>: <span class="hljs-number">270</span>,
  <span class="hljs-attr">innerRadius</span>: <span class="hljs-number">0.4</span>,
  <span class="hljs-attr">radius</span>: <span class="hljs-number">0.5</span>,
  <span class="hljs-attr">animation</span>: { <span class="hljs-attr">duration</span>: <span class="hljs-number">400</span> }
})
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend()
chart.append([pie, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>