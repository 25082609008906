<template><section><h3>修改雷达图点形状</h3>
<block-demo tip="" source="const data = [
  { date: &#39;体力&#39;, category: &#39;诸葛亮&#39;, sales: 100 },
  { date: &#39;武力&#39;, category: &#39;诸葛亮&#39;, sales: 69 },
  { date: &#39;智力&#39;, category: &#39;诸葛亮&#39;, sales: 100 },
  { date: &#39;统帅&#39;, category: &#39;诸葛亮&#39;, sales: 95 },
  { date: &#39;魅力&#39;, category: &#39;诸葛亮&#39;, sales: 95 },
  { date: &#39;忠诚&#39;, category: &#39;诸葛亮&#39;, sales: 100 }
]

const { Chart, Radar, Legend, Tooltip } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})
chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;sales&#39;,
  text: &#39;date&#39;
})
const radar = new Radar().style(&#39;scale&#39;, false)
radar.style(&#39;section&#39;, d =&gt; ({ opacity: 0.3 })).style(&#39;section:hover&#39;, d =&gt; ({ opacity: 0.5 }))

const legend = new Legend({ align: [&#39;center&#39;, &#39;80%&#39;] })

chart.append([radar, legend, new Tooltip()])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;体力&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">100</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;武力&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">69</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;智力&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">100</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;统帅&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;魅力&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;忠诚&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;诸葛亮&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">100</span> }
]

<span class="hljs-keyword">const</span> { Chart, Radar, Legend, Tooltip } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})
<span class="hljs-keyword">const</span> radar = <span class="hljs-keyword">new</span> Radar().style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
radar.style(<span class="hljs-string">&#x27;section&#x27;</span>, <span class="hljs-function"><span class="hljs-params">d</span> =&gt;</span> ({ <span class="hljs-attr">opacity</span>: <span class="hljs-number">0.3</span> })).style(<span class="hljs-string">&#x27;section:hover&#x27;</span>, <span class="hljs-function"><span class="hljs-params">d</span> =&gt;</span> ({ <span class="hljs-attr">opacity</span>: <span class="hljs-number">0.5</span> }))

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;80%&#x27;</span>] })

chart.append([radar, legend, <span class="hljs-keyword">new</span> Tooltip()])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>