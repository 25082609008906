<template><section><h2>折线图与柱状图组合</h2>
<block-demo tip="" source="const data = [
  { date: &#39;1月&#39;, category: &#39;降水量&#39;, val: 15.2 },
  { date: &#39;2月&#39;, category: &#39;降水量&#39;, val: 19.2 },
  { date: &#39;3月&#39;, category: &#39;降水量&#39;, val: 11.2 },
  { date: &#39;4月&#39;, category: &#39;降水量&#39;, val: 45.2 },
  { date: &#39;5月&#39;, category: &#39;降水量&#39;, val: 55.2 },
  { date: &#39;6月&#39;, category: &#39;降水量&#39;, val: 75.2 },
  { date: &#39;7月&#39;, category: &#39;降水量&#39;, val: 95.2 },
  { date: &#39;8月&#39;, category: &#39;降水量&#39;, val: 135.2 },
  { date: &#39;9月&#39;, category: &#39;降水量&#39;, val: 162.2 },
  { date: &#39;10月&#39;, category: &#39;降水量&#39;, val: 32.2 },
  { date: &#39;11月&#39;, category: &#39;降水量&#39;, val: 32.2 },
  { date: &#39;12月&#39;, category: &#39;降水量&#39;, val: 15.2 },
  { date: &#39;1月&#39;, category: &#39;气温&#39;, val: 2.2 },
  { date: &#39;2月&#39;, category: &#39;气温&#39;, val: 3.2 },
  { date: &#39;3月&#39;, category: &#39;气温&#39;, val: 5.2 },
  { date: &#39;4月&#39;, category: &#39;气温&#39;, val: 6.2 },
  { date: &#39;5月&#39;, category: &#39;气温&#39;, val: 8.2 },
  { date: &#39;6月&#39;, category: &#39;气温&#39;, val: 15.2 },
  { date: &#39;7月&#39;, category: &#39;气温&#39;, val: 25.2 },
  { date: &#39;8月&#39;, category: &#39;气温&#39;, val: 23.2 },
  { date: &#39;9月&#39;, category: &#39;气温&#39;, val: 24.2 },
  { date: &#39;10月&#39;, category: &#39;气温&#39;, val: 16.2 },
  { date: &#39;11月&#39;, category: &#39;气温&#39;, val: 12.2 },
  { date: &#39;12月&#39;, category: &#39;气温&#39;, val: 6.6 }
]

const { Chart, Line, Bar, Legend, Axis } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;val&#39;,
  text: &#39;date&#39;
})

const line = new Line()

const axisLeft = new Axis({
  orient: &#39;left&#39;,
  formatter: val =&gt; {
    return `${val} ml`
  }
})
  .style(&#39;axis&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;grid&#39;, { lineDash: [3, 3] })

const axisBottom = new Axis()
  .style(&#39;scale&#39;, hideAxis)
  .style(&#39;label&#39;, hideAxis)
  .style(&#39;grid&#39;, false)

function hideAxis(attrs, data, i) {
  if (i % 2 !== 0) {
    return false
  }
}
const legend = new Legend({ align: [&#39;center&#39;, &#39;bottom&#39;] }).style(&#39;icon&#39;, { borderRadius: 10 }).style(&#39;text&#39;, { fontSize: 12 })

chart.append([line, axisBottom, axisLeft, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;1月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">19.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;3月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">11.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;4月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">45.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;5月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;6月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;7月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;8月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">135.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;9月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">162.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;10月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;11月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;12月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;降水量&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;1月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">2.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;2月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">3.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;3月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">5.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;4月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;5月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">8.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;6月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;7月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">25.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;8月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">23.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;9月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">24.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;10月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">16.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;11月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">12.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;12月&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;气温&#x27;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.6</span> }
]

<span class="hljs-keyword">const</span> { Chart, Line, Bar, Legend, Axis } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;val&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})

<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line()

<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-params">val</span> =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${val}</span> ml`</span>
  }
})
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">lineDash</span>: [<span class="hljs-number">3</span>, <span class="hljs-number">3</span>] })

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis()
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, hideAxis)
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, hideAxis)
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)

<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">hideAxis</span>(<span class="hljs-params">attrs, data, i</span>) </span>{
  <span class="hljs-keyword">if</span> (i % <span class="hljs-number">2</span> !== <span class="hljs-number">0</span>) {
    <span class="hljs-keyword">return</span> <span class="hljs-literal">false</span>
  }
}
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;center&#x27;</span>, <span class="hljs-string">&#x27;bottom&#x27;</span>] }).style(<span class="hljs-string">&#x27;icon&#x27;</span>, { <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">10</span> }).style(<span class="hljs-string">&#x27;text&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span> })

chart.append([line, axisBottom, axisLeft, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>