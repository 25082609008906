var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("柱状图颜色渐变")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  { value: 7, label: '易涝区' },\n  { value: 11, label: '台风易受灾区' },\n  { value: 20, label: '坍塌' },\n  { value: 6, label: '滑坡' },\n  { value: 3, label: '泥石流危险区' }\n]\nconst BAR_WIDTH = 30\nconst color = '#30FBAB'\nconst { Chart, Bar, Tooltip, Axis, theme } = qcharts\ntheme.set({ colors: [color] })\n\nconst { Gradient } = spritejs\nconst chart = new Chart({\n  container: '#app'\n})\n\nchart.source(data, {\n  value: 'value',\n  text: 'label'\n})\n\nconst bar = new Bar({ barWidth: BAR_WIDTH })\n  .style('text', (attrs, data, i, j) => {\n    const size = attrs.barAttrs.size\n    const points = attrs.barAttrs.points\n    return {\n      padding: 0,\n      rotate: 0,\n      text: data.value,\n      fillColor: color,\n      anchor: [0.5, 1],\n      pos: [(points[0][0] + points[1][0]) / 2, points[0][1] - 20]\n    }\n  })\n  .style('pillar', (attrs, data, i, j) => {\n    const points = attrs.points\n    points[0][1] = points[0][1] + BAR_WIDTH / 2\n    return { points }\n  })\nconst tooltip = new Tooltip({\n  formatter: d => `${d.label}: ${d.value}`\n})\nconst axisLeft = new Axis({ orient: 'left' })\n  .style('axis', false)\n  .style('scale', false)\n  .style('grid', { strokeColor: 'rgba(48, 251, 171,0.5)' })\n  .style('label', { fillColor: color })\nconst axisBottom = new Axis()\n  .style('scale', false)\n  .style('axis', { strokeColor: 'rgba(48, 251, 171,1)' })\n  .style('label', { padding: [20, 5], fillColor: color })\n  .style('grid', false)\n\nchart.append([bar, tooltip, axisBottom, axisLeft])\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("7")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'易涝区'")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("11")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'台风易受灾区'")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("20")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'坍塌'")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'滑坡'")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'泥石流危险区'")
                ]),
                _vm._v(" }\n]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" BAR_WIDTH = "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("30")
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" color = "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#30FBAB'")
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(
                  " { Chart, Bar, Tooltip, Axis, theme } = qcharts\ntheme.set({ "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("colors")
                ]),
                _vm._v(": [color] })\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Gradient } = spritejs\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\n\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'value'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'label'")
                ]),
                _vm._v("\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" bar = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Bar({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("barWidth")
                ]),
                _vm._v(": BAR_WIDTH })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'text'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attrs, data, i, j")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" size = attrs.barAttrs.size\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" points = attrs.barAttrs.points\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("padding")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("rotate")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": data.value,\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": color,\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("anchor")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.5")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("],\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("pos")
                ]),
                _vm._v(": [(points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("] + points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]) / "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(", points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("] - "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("20")
                ]),
                _vm._v("]\n    }\n  })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'pillar'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attrs, data, i, j")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" points = attrs.points\n    points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("] = points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("] + BAR_WIDTH / "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" { points }\n  })\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" tooltip = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Tooltip({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("formatter")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("d")
                  ]),
                  _vm._v(" =>")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("`"),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.label}")
                  ]),
                  _vm._v(": "),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.value}")
                  ]),
                  _vm._v("`")
                ]),
                _vm._v("\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" axisLeft = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Axis({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("orient")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'left'")
                ]),
                _vm._v(" })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'axis'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'scale'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'grid'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'rgba(48, 251, 171,0.5)'")
                ]),
                _vm._v(" })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'label'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": color })\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" axisBottom = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Axis()\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'scale'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'axis'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'rgba(48, 251, 171,1)'")
                ]),
                _vm._v(" })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'label'")
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("padding")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("20")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5")
                ]),
                _vm._v("], "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": color })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'grid'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(
                  ")\n\nchart.append([bar, tooltip, axisBottom, axisLeft])\n"
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }