var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("隐藏坐标轴")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              'const data = [\n  { value: 2200, label: "05-01" },\n  { value: 6000, label: "05-02" },\n  { value: 5600, label: "05-03" },\n  { value: 8500, label: "05-04" },\n  { value: 13600, label: "05-05" },\n  { value: 10800, label: "05-06" },\n  { value: 6400, label: "05-07" },\n  { value: 3500, label: "05-08" },\n]\nconst { Chart, Bar, Tooltip, Axis } = qcharts\nconst chart = new Chart({\n  container: "#app",\n})\nchart.source(data, {\n  value: "value",\n  text: "label",\n})\nlet bool = true\nconst bar = new Bar({})\n  .style("pillar", { bgcolor: "#47A1FF" })\n  .style("text", (attrs, data, i, j) => {\n    let size = attrs.barAttrs.size\n    let points = attrs.barAttrs.points\n    let str = data.value + ""\n\n    let result = str\n      .split("")\n      .reverse()\n      .reduce((prev, next, index) => {\n        return (index % 3 ? next : next + ",") + prev\n      })\n    return {\n      fillColor: "#2AAAFF",\n      rotate: 0,\n      text: result,\n      anchor: [0.5, 1],\n      pos: [(points[0][0] + points[1][0]) / 2, points[0][1]],\n      padding: 0,\n    }\n  })\nconst tooltip = new Tooltip({\n  formatter: (d) => `${d.label}: ${d.value}`,\n})\n\nconst axisBottom = new Axis()\n  .style("axis", false)\n  .style("scale", false)\n  .style("label", function(attrs, data, i) {\n    return {\n      rotate: 45,\n      translate: [-10, 0],\n      anchor: [0, 0],\n    }\n  })\n\nchart.append([bar, tooltip, axisBottom])\n'
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2200")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-01"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6000")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-02"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5600")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-03"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8500")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-04"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("13600")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-05"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10800")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-06"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6400")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-07"')
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3500")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("label")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"05-08"')
                ]),
                _vm._v(" },\n]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Chart, Bar, Tooltip, Axis } = qcharts\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"#app"')
                ]),
                _vm._v(",\n})\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"value"')
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"label"')
                ]),
                _vm._v(",\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" bool = "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" bar = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Bar({})\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"pillar"')
                ]),
                _vm._v(", { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("bgcolor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"#47A1FF"')
                ]),
                _vm._v(" })\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"text"')
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attrs, data, i, j")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" size = attrs.barAttrs.size\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" points = attrs.barAttrs.points\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" str = data.value + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v("\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" result = str\n      .split("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v(")\n      .reverse()\n      .reduce("),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("prev, next, index")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" (index % "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3")
                ]),
                _vm._v(" ? next : next + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('","')
                ]),
                _vm._v(") + prev\n      })\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"#2AAAFF"')
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("rotate")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": result,\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("anchor")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.5")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("],\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("pos")
                ]),
                _vm._v(": [(points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("] + points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]) / "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(", points["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("]["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("]],\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("padding")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(",\n    }\n  })\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" tooltip = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Tooltip({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("formatter")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("d")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("`"),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.label}")
                  ]),
                  _vm._v(": "),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.value}")
                  ]),
                  _vm._v("`")
                ]),
                _vm._v(",\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" axisBottom = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Axis()\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"axis"')
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"scale"')
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\n  .style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"label"')
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                    _vm._v("function")
                  ]),
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attrs, data, i")
                  ]),
                  _vm._v(") ")
                ]),
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("rotate")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("45")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("translate")
                ]),
                _vm._v(": [-"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("],\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("anchor")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(
                  "],\n    }\n  })\n\nchart.append([bar, tooltip, axisBottom])\n"
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }