<template><section><h2>负值堆叠条形图 2</h2>
<block-demo tip="" source="const data = [
  {
    data: &quot;05-12&quot;,
    type: &quot;图例一&quot;,
    value: -11,
  },
  {
    data: &quot;05-12&quot;,
    type: &quot;图例二&quot;,
    value: 12.2,
  },
  {
    data: &quot;05-13&quot;,
    type: &quot;图例一&quot;,
    value: -28,
  },
  {
    data: &quot;05-13&quot;,
    type: &quot;图例二&quot;,
    value: 31,
  },
  {
    data: &quot;05-14&quot;,
    type: &quot;图例一&quot;,
    value: -36,
  },
  {
    data: &quot;05-14&quot;,
    type: &quot;图例二&quot;,
    value: 18,
  },
  {
    data: &quot;05-15&quot;,
    type: &quot;图例一&quot;,
    value: -58,
  },
  {
    data: &quot;05-15&quot;,
    type: &quot;图例二&quot;,
    value: 30.2,
  },
  {
    data: &quot;05-16&quot;,
    type: &quot;图例一&quot;,
    value: -86.2,
  },
  {
    data: &quot;05-16&quot;,
    type: &quot;图例二&quot;,
    value: 41.2,
  },
  {
    data: &quot;05-17&quot;,
    type: &quot;图例一&quot;,
    value: -71.2,
  },
  {
    data: &quot;05-17&quot;,
    type: &quot;图例二&quot;,
    value: 45.2,
  },
  {
    data: &quot;05-18&quot;,
    type: &quot;图例一&quot;,
    value: -41.2,
  },
  {
    data: &quot;05-18&quot;,
    type: &quot;图例二&quot;,
    value: 22.2,
  },
  {
    data: &quot;05-19&quot;,
    type: &quot;图例一&quot;,
    value: -16.2,
  },
  {
    data: &quot;05-19&quot;,
    type: &quot;图例二&quot;,
    value: 20.2,
  },
]
const BAR_WIDTH = 20
const SCOPE_PIXEL = 20
const { Chart, Bar, Tooltip, Axis, Legend } = qcharts
const chart = new Chart({
  container: &quot;#app&quot;,
})
chart.source(data, {
  row: &quot;type&quot;,
  value: &quot;value&quot;,
  text: &quot;data&quot;,
})
const bar = new Bar({
  stack: true,
  transpose: true,
  barWidth: BAR_WIDTH,
  polygon: true,
})
bar.style(&quot;backgroundpillar&quot;, (attr, data, i) =&gt; {
  let points = attr.points
  let size = attr.size
  points[0][1] = points[0][1] + (size[1] - BAR_WIDTH) / 2
  points[1][1] = points[0][1]
  points[2][1] = points[2][1] - (size[1] - BAR_WIDTH) / 2
  points[3][1] = points[2][1]
  points[3][0] = points[3][0] + SCOPE_PIXEL
  points[2][0] = points[2][0] - SCOPE_PIXEL
  const opacity = 0.1
  return { points, opacity }
})
bar.style(&quot;pillar&quot;, (attr, data, i, j) =&gt; {
  let points = attr.points
  if (points[3][0] !== points[2][0]) {
    if (j === 0) {
      points[3][0] = points[3][0] + SCOPE_PIXEL
      // return { points }
    } else {
      points[2][0] = points[2][0] - SCOPE_PIXEL
    }
  }
  return { points }
})
const tooltip = new Tooltip({
  formatter: (d) =&gt; ` ${d.type}: ${d.value}`,
})
const legend = new Legend({ align: [&quot;center&quot;, &quot;bottom&quot;] })
const axisBottom = new Axis().style(&quot;scale&quot;, false).style(&quot;grid&quot;, false)
const axisLeft = new Axis({
  orient: &quot;left&quot;,
  formatter: (a, b) =&gt; {
    return ` ${a}`
  },
})
  .style(&quot;grid&quot;, false)
  .style(&quot;scale&quot;, false)
  .style(&quot;label&quot;, true)
chart.append([bar, tooltip, legend, axisBottom, axisLeft])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-12&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">11</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-12&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">12.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-13&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">28</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-13&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">31</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-14&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">36</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-14&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">18</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-15&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">58</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-15&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">30.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-16&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">86.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-16&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">41.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-17&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">71.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-17&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">45.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-18&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">41.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-18&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">22.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-19&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
    <span class="hljs-attr">value</span>: -<span class="hljs-number">16.2</span>,
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&quot;05-19&quot;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&quot;图例二&quot;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">20.2</span>,
  },
]
<span class="hljs-keyword">const</span> BAR_WIDTH = <span class="hljs-number">20</span>
<span class="hljs-keyword">const</span> SCOPE_PIXEL = <span class="hljs-number">20</span>
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis, Legend } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&quot;type&quot;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;value&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;data&quot;</span>,
})
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({
  <span class="hljs-attr">stack</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">transpose</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">barWidth</span>: BAR_WIDTH,
  <span class="hljs-attr">polygon</span>: <span class="hljs-literal">true</span>,
})
bar.style(<span class="hljs-string">&quot;backgroundpillar&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
  <span class="hljs-keyword">let</span> points = attr.points
  <span class="hljs-keyword">let</span> size = attr.size
  points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] + (size[<span class="hljs-number">1</span>] - BAR_WIDTH) / <span class="hljs-number">2</span>
  points[<span class="hljs-number">1</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>]
  points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>] - (size[<span class="hljs-number">1</span>] - BAR_WIDTH) / <span class="hljs-number">2</span>
  points[<span class="hljs-number">3</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>]
  points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] + SCOPE_PIXEL
  points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] - SCOPE_PIXEL
  <span class="hljs-keyword">const</span> opacity = <span class="hljs-number">0.1</span>
  <span class="hljs-keyword">return</span> { points, opacity }
})
bar.style(<span class="hljs-string">&quot;pillar&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i, j</span>) =&gt;</span> {
  <span class="hljs-keyword">let</span> points = attr.points
  <span class="hljs-keyword">if</span> (points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] !== points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>]) {
    <span class="hljs-keyword">if</span> (j === <span class="hljs-number">0</span>) {
      points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] + SCOPE_PIXEL
      <span class="hljs-comment">// return { points }</span>
    } <span class="hljs-keyword">else</span> {
      points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] - SCOPE_PIXEL
    }
  }
  <span class="hljs-keyword">return</span> { points }
})
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">` <span class="hljs-subst">${d.type}</span>: <span class="hljs-subst">${d.value}</span>`</span>,
})
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&quot;center&quot;</span>, <span class="hljs-string">&quot;bottom&quot;</span>] })
<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis().style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>).style(<span class="hljs-string">&quot;grid&quot;</span>, <span class="hljs-literal">false</span>)
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&quot;left&quot;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">a, b</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">` <span class="hljs-subst">${a}</span>`</span>
  },
})
  .style(<span class="hljs-string">&quot;grid&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;label&quot;</span>, <span class="hljs-literal">true</span>)
chart.append([bar, tooltip, legend, axisBottom, axisLeft])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>