var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Donut Chart 动态环图")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  {\n    year: '2016',\n    sales: 17,\n    selected: true\n  },\n  {\n    year: '2017',\n    sales: 28\n  },\n  {\n    year: '2018',\n    sales: 25\n  },\n  {\n    year: '2019',\n    sales: 7\n  },\n  {\n    year: '2020',\n    sales: 22\n  }\n]\nconst length = data.length\nlet counter = 0\nconst { Chart, Pie } = qcharts\nconst { Label } = spritejs\nconst chart = new Chart({\n  container: '#app'\n})\n\nchart.source(data, {\n  row: 'year',\n  value: 'sales'\n})\n\nconst pie = new Pie({\n  innerRadius: 0.4,\n  radius: 0.5,\n  animation: { duration: 400 },\n  formatter: function(str) {\n    return `${str} %`\n  }\n})\n\nchart.append([pie])\n\nsetInterval(changeData, 3000)\n//数据被选中动画模拟，轮流设置数据的selected属性，同时移除上一个数据的selected属性\nfunction changeData() {\n  if (counter < 4) {\n    counter++\n  } else {\n    counter = 0\n  }\n  pie.dataset.forEach((item, ind) => {\n    if (item.state === 'hover' && ind !== counter) {\n      item.state = 'default'\n    }\n  })\n  pie.dataset[counter].state = 'hover'\n}\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'2016'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("17")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("selected")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'2017'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("28")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'2018'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("25")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'2019'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("7")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'2020'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("22")
                ]),
                _vm._v("\n  }\n]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" length = data.length\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("let")
                ]),
                _vm._v(" counter = "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Chart, Pie } = qcharts\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Label } = spritejs\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\n\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("row")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'year'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'sales'")
                ]),
                _vm._v("\n})\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" pie = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Pie({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("innerRadius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.4")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("radius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.5")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("animation")
                ]),
                _vm._v(": { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("duration")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("400")
                ]),
                _vm._v(" },\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("formatter")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                    _vm._v("function")
                  ]),
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("str")
                  ]),
                  _vm._v(") ")
                ]),
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("`"),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${str}")
                  ]),
                  _vm._v(" %`")
                ]),
                _vm._v("\n  }\n})\n\nchart.append([pie])\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-built_in" } }, [
                  _vm._v("setInterval")
                ]),
                _vm._v("(changeData, "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3000")
                ]),
                _vm._v(")\n"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "//数据被选中动画模拟，轮流设置数据的selected属性，同时移除上一个数据的selected属性"
                  )
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                    _vm._v("function")
                  ]),
                  _vm._v(" "),
                  _c("span", { pre: true, attrs: { class: "hljs-title" } }, [
                    _vm._v("changeData")
                  ]),
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }),
                  _vm._v(") ")
                ]),
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (counter < "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("4")
                ]),
                _vm._v(") {\n    counter++\n  } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" {\n    counter = "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("\n  }\n  pie.dataset.forEach("),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("item, ind")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (item.state === "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'hover'")
                ]),
                _vm._v(" && ind !== counter) {\n      item.state = "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'default'")
                ]),
                _vm._v("\n    }\n  })\n  pie.dataset[counter].state = "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'hover'")
                ]),
                _vm._v("\n}\n")
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }