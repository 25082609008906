<template><section><h2>间距堆叠条形图 2</h2>
<block-demo tip="" source="const data = [
  {
    data: &#39;自然灾害&#39;,
    type: &#39;一般&#39;,
    value: 30
  },
  {
    data: &#39;自然灾害&#39;,
    type: &#39;较大&#39;,
    value: 10
  },
  {
    data: &#39;自然灾害&#39;,
    type: &#39;重大&#39;,
    value: 8
  },
  {
    data: &#39;自然灾害&#39;,
    type: &#39;特大&#39;,
    value: 2
  },
  {
    data: &#39;事故灾害&#39;,
    type: &#39;一般&#39;,
    value: 19
  },
  {
    data: &#39;事故灾害&#39;,
    type: &#39;较大&#39;,
    value: 12
  },
  {
    data: &#39;事故灾害&#39;,
    type: &#39;重大&#39;,
    value: 6
  },
  {
    data: &#39;事故灾害&#39;,
    type: &#39;特大&#39;,
    value: 2
  },
  {
    data: &#39;社会安全&#39;,
    type: &#39;一般&#39;,
    value: 14
  },
  {
    data: &#39;社会安全&#39;,
    type: &#39;较大&#39;,
    value: 9
  },
  {
    data: &#39;社会安全&#39;,
    type: &#39;重大&#39;,
    value: 7
  },
  {
    data: &#39;社会安全&#39;,
    type: &#39;特大&#39;,
    value: 2
  },
  {
    data: &#39;公共卫生&#39;,
    type: &#39;一般&#39;,
    value: 10
  },
  {
    data: &#39;公共卫生&#39;,
    type: &#39;较大&#39;,
    value: 5
  },
  {
    data: &#39;公共卫生&#39;,
    type: &#39;重大&#39;,
    value: 4
  },
  {
    data: &#39;公共卫生&#39;,
    type: &#39;特大&#39;,
    value: 1
  }
]
const BAR_WIDTH = 30
const SCOPE_PIXEL = 20
const { Chart, Bar, Tooltip, Axis, Legend, theme } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})
chart.source(data, {
  row: &#39;type&#39;,
  value: &#39;value&#39;,
  text: &#39;data&#39;
})
const colors = [&#39;#1ACE87&#39;, &#39;#30FBAB&#39;, &#39;#FBB70B&#39;, &#39;#FFEB3E&#39;]
const bar = new Bar({
  stack: true,
  transpose: true,
  barWidth: BAR_WIDTH,
  stackGap: 5,
  polygon: true
})
  .style(&#39;pillar&#39;, (attr, data, i, j) =&gt; {
    let points = attr.points
    if (points[0][0] === points[1][0] &amp;&amp; points[0][0] === 0) {
      points[2][0] = 0
    } else {
      points[2][0] = points[2][0] - SCOPE_PIXEL
    }
    if (points[0][0] !== 0) {
      points[3][0] = points[3][0] - SCOPE_PIXEL
    }
    return { points, fillColor: colors[j] }
  })
  .style(&#39;backgroundpillar&#39;, (attr, data, i) =&gt; {
    let attrs = attr
    let points = attrs.points
    let size = attrs.size
    points[0][1] = points[0][1] + (size[1] - BAR_WIDTH) / 2
    points[1][1] = points[0][1]
    points[2][1] = points[2][1] - (size[1] - BAR_WIDTH) / 2
    points[3][1] = points[2][1]
    points[2][0] = points[2][0] - SCOPE_PIXEL
    const opacity = 0.05
    return { points, opacity }
  })
const legend = new Legend({ align: [&#39;right&#39;, &#39;top&#39;] }).style(&#39;point&#39;, (attr, data, i) =&gt; {
  return {
    borderRadius: 6,
    bgcolor: colors[i]
  }
})
const axisLeft = new Axis({
  orient: &#39;left&#39;
})
  .style(&#39;axis&#39;, { strokeColor: &#39;#1DCE91&#39; })
  .style(&#39;grid&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;label&#39;, { fillColor: &#39;#30FBAB&#39; })
const axisBottom = new Axis()
  .style(&#39;axis&#39;, false)
  .style(&#39;label&#39;, { fillColor: &#39;#30FBAB&#39; })
  .style(&#39;grid&#39;, { strokeColor: &#39;#30FBAB&#39; })
chart.append([bar, legend, axisLeft, axisBottom])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;自然灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;一般&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">30</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;自然灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;较大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">10</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;自然灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;重大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">8</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;自然灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;特大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">2</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;事故灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;一般&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">19</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;事故灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;较大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">12</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;事故灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;重大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">6</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;事故灾害&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;特大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">2</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;社会安全&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;一般&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">14</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;社会安全&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;较大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">9</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;社会安全&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;重大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">7</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;社会安全&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;特大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">2</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;公共卫生&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;一般&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">10</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;公共卫生&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;较大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">5</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;公共卫生&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;重大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">4</span>
  },
  {
    <span class="hljs-attr">data</span>: <span class="hljs-string">&#x27;公共卫生&#x27;</span>,
    <span class="hljs-attr">type</span>: <span class="hljs-string">&#x27;特大&#x27;</span>,
    <span class="hljs-attr">value</span>: <span class="hljs-number">1</span>
  }
]
<span class="hljs-keyword">const</span> BAR_WIDTH = <span class="hljs-number">30</span>
<span class="hljs-keyword">const</span> SCOPE_PIXEL = <span class="hljs-number">20</span>
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis, Legend, theme } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})
chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;type&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;data&#x27;</span>
})
<span class="hljs-keyword">const</span> colors = [<span class="hljs-string">&#x27;#1ACE87&#x27;</span>, <span class="hljs-string">&#x27;#30FBAB&#x27;</span>, <span class="hljs-string">&#x27;#FBB70B&#x27;</span>, <span class="hljs-string">&#x27;#FFEB3E&#x27;</span>]
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({
  <span class="hljs-attr">stack</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">transpose</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">barWidth</span>: BAR_WIDTH,
  <span class="hljs-attr">stackGap</span>: <span class="hljs-number">5</span>,
  <span class="hljs-attr">polygon</span>: <span class="hljs-literal">true</span>
})
  .style(<span class="hljs-string">&#x27;pillar&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> points = attr.points
    <span class="hljs-keyword">if</span> (points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] === points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>] &amp;&amp; points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] === <span class="hljs-number">0</span>) {
      points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] = <span class="hljs-number">0</span>
    } <span class="hljs-keyword">else</span> {
      points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] - SCOPE_PIXEL
    }
    <span class="hljs-keyword">if</span> (points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] !== <span class="hljs-number">0</span>) {
      points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">3</span>][<span class="hljs-number">0</span>] - SCOPE_PIXEL
    }
    <span class="hljs-keyword">return</span> { points, <span class="hljs-attr">fillColor</span>: colors[j] }
  })
  .style(<span class="hljs-string">&#x27;backgroundpillar&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> attrs = attr
    <span class="hljs-keyword">let</span> points = attrs.points
    <span class="hljs-keyword">let</span> size = attrs.size
    points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] + (size[<span class="hljs-number">1</span>] - BAR_WIDTH) / <span class="hljs-number">2</span>
    points[<span class="hljs-number">1</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>]
    points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>] - (size[<span class="hljs-number">1</span>] - BAR_WIDTH) / <span class="hljs-number">2</span>
    points[<span class="hljs-number">3</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>]
    points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] = points[<span class="hljs-number">2</span>][<span class="hljs-number">0</span>] - SCOPE_PIXEL
    <span class="hljs-keyword">const</span> opacity = <span class="hljs-number">0.05</span>
    <span class="hljs-keyword">return</span> { points, opacity }
  })
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&#x27;right&#x27;</span>, <span class="hljs-string">&#x27;top&#x27;</span>] }).style(<span class="hljs-string">&#x27;point&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attr, data, i</span>) =&gt;</span> {
  <span class="hljs-keyword">return</span> {
    <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">6</span>,
    <span class="hljs-attr">bgcolor</span>: colors[i]
  }
})
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span>
})
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#1DCE91&#x27;</span> })
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, { <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#30FBAB&#x27;</span> })
<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis()
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, { <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&#x27;#30FBAB&#x27;</span> })
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#30FBAB&#x27;</span> })
chart.append([bar, legend, axisLeft, axisBottom])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>