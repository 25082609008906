<template><section><h2>Donut Chart 动态环图</h2>
<block-demo tip="" source="const data = [
  { name: &#39;存储仓库&#39;, value: 50 },
  { name: &#39;装备物资企业&#39;, value: 17 },
  { name: &#39;运输机构&#39;, value: 1 },
  { name: &#39;通讯保障机构&#39;, value: 0 },
  { name: &#39;医疗卫生机构&#39;, value: 182 },
  { name: &#39;应急避难场所&#39;, value: 619 }
]
const { Chart, Pie, Legend, Tooltip } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;name&#39;,
  value: &#39;value&#39;
})

const pie = new Pie({
  startAngle: -90,
  endAngle: 270,
  innerRadius: 0.4,
  radius: 0.7,
  clientRect: { top: &#39;10%&#39;, left: &#39;10%&#39;, right: &#39;10%&#39;, bottom: &#39;10%&#39; }
})
pie.style(&#39;sector&#39;, { lineWidth: 1, strokeColor: &#39;#fff&#39; })
pie.style(&#39;guideline&#39;, true)
pie.style(&#39;guideText&#39;, { fontSize: &#39;12px&#39; })
const legend = new Legend()
chart.append([pie, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;存储仓库&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">50</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;装备物资企业&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">17</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;运输机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">1</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;通讯保障机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">0</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;医疗卫生机构&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">182</span> },
  { <span class="hljs-attr">name</span>: <span class="hljs-string">&#x27;应急避难场所&#x27;</span>, <span class="hljs-attr">value</span>: <span class="hljs-number">619</span> }
]
<span class="hljs-keyword">const</span> { Chart, Pie, Legend, Tooltip } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;name&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>
})

<span class="hljs-keyword">const</span> pie = <span class="hljs-keyword">new</span> Pie({
  <span class="hljs-attr">startAngle</span>: -<span class="hljs-number">90</span>,
  <span class="hljs-attr">endAngle</span>: <span class="hljs-number">270</span>,
  <span class="hljs-attr">innerRadius</span>: <span class="hljs-number">0.4</span>,
  <span class="hljs-attr">radius</span>: <span class="hljs-number">0.7</span>,
  <span class="hljs-attr">clientRect</span>: { <span class="hljs-attr">top</span>: <span class="hljs-string">&#x27;10%&#x27;</span>, <span class="hljs-attr">left</span>: <span class="hljs-string">&#x27;10%&#x27;</span>, <span class="hljs-attr">right</span>: <span class="hljs-string">&#x27;10%&#x27;</span>, <span class="hljs-attr">bottom</span>: <span class="hljs-string">&#x27;10%&#x27;</span> }
})
pie.style(<span class="hljs-string">&#x27;sector&#x27;</span>, { <span class="hljs-attr">lineWidth</span>: <span class="hljs-number">1</span>, <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;#fff&#x27;</span> })
pie.style(<span class="hljs-string">&#x27;guideline&#x27;</span>, <span class="hljs-literal">true</span>)
pie.style(<span class="hljs-string">&#x27;guideText&#x27;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-string">&#x27;12px&#x27;</span> })
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend()
chart.append([pie, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>