var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h3", [_vm._v("修改雷达图点形状")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  { date: '体力', category: '诸葛亮', sales: 100 },\n  { date: '武力', category: '诸葛亮', sales: 69 },\n  { date: '智力', category: '诸葛亮', sales: 100 },\n  { date: '统帅', category: '诸葛亮', sales: 95 },\n  { date: '魅力', category: '诸葛亮', sales: 95 },\n  { date: '忠诚', category: '诸葛亮', sales: 100 }\n]\n\nconst { Chart, Radar, Legend, Tooltip } = qcharts\n\nconst chart = new Chart({\n  container: '#app'\n})\nchart.source(data, {\n  row: 'category',\n  value: 'sales',\n  text: 'date'\n})\nconst radar = new Radar().style('scale', false)\nradar.style('section', d => ({ opacity: 0.3 })).style('section:hover', d => ({ opacity: 0.5 }))\n\nconst legend = new Legend({ align: ['center', '80%'] })\n\nchart.append([radar, legend, new Tooltip()])\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'体力'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("100")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'武力'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("69")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'智力'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("100")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'统帅'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("95")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'魅力'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("95")
                ]),
                _vm._v(" },\n  { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("date")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'忠诚'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("category")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'诸葛亮'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("100")
                ]),
                _vm._v(" }\n]\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" { Chart, Radar, Legend, Tooltip } = qcharts\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("row")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'category'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'sales'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'date'")
                ]),
                _vm._v("\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" radar = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Radar().style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'scale'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(")\nradar.style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'section'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("d")
                  ]),
                  _vm._v(" =>")
                ]),
                _vm._v(" ({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("opacity")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.3")
                ]),
                _vm._v(" })).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'section:hover'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("d")
                  ]),
                  _vm._v(" =>")
                ]),
                _vm._v(" ({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("opacity")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.5")
                ]),
                _vm._v(" }))\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" legend = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Legend({ "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("align")
                ]),
                _vm._v(": ["),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'center'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'80%'")
                ]),
                _vm._v("] })\n\nchart.append([radar, legend, "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Tooltip()])\n")
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }