<template><section><h2>柱状图颜色渐变</h2>
<block-demo tip="" source="const data = [
  { value: 7, label: &#39;易涝区&#39; },
  { value: 11, label: &#39;台风易受灾区&#39; },
  { value: 20, label: &#39;坍塌&#39; },
  { value: 6, label: &#39;滑坡&#39; },
  { value: 3, label: &#39;泥石流危险区&#39; }
]
const BAR_WIDTH = 30
const color = &#39;#30FBAB&#39;
const { Chart, Bar, Tooltip, Axis, theme } = qcharts
theme.set({ colors: [color] })

const { Gradient } = spritejs
const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  value: &#39;value&#39;,
  text: &#39;label&#39;
})

const bar = new Bar({ barWidth: BAR_WIDTH })
  .style(&#39;text&#39;, (attrs, data, i, j) =&gt; {
    const size = attrs.barAttrs.size
    const points = attrs.barAttrs.points
    return {
      padding: 0,
      rotate: 0,
      text: data.value,
      fillColor: color,
      anchor: [0.5, 1],
      pos: [(points[0][0] + points[1][0]) / 2, points[0][1] - 20]
    }
  })
  .style(&#39;pillar&#39;, (attrs, data, i, j) =&gt; {
    const points = attrs.points
    points[0][1] = points[0][1] + BAR_WIDTH / 2
    return { points }
  })
const tooltip = new Tooltip({
  formatter: d =&gt; `${d.label}: ${d.value}`
})
const axisLeft = new Axis({ orient: &#39;left&#39; })
  .style(&#39;axis&#39;, false)
  .style(&#39;scale&#39;, false)
  .style(&#39;grid&#39;, { strokeColor: &#39;rgba(48, 251, 171,0.5)&#39; })
  .style(&#39;label&#39;, { fillColor: color })
const axisBottom = new Axis()
  .style(&#39;scale&#39;, false)
  .style(&#39;axis&#39;, { strokeColor: &#39;rgba(48, 251, 171,1)&#39; })
  .style(&#39;label&#39;, { padding: [20, 5], fillColor: color })
  .style(&#39;grid&#39;, false)

chart.append([bar, tooltip, axisBottom, axisLeft])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">value</span>: <span class="hljs-number">7</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;易涝区&#x27;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">11</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;台风易受灾区&#x27;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;坍塌&#x27;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">6</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;滑坡&#x27;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">3</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&#x27;泥石流危险区&#x27;</span> }
]
<span class="hljs-keyword">const</span> BAR_WIDTH = <span class="hljs-number">30</span>
<span class="hljs-keyword">const</span> color = <span class="hljs-string">&#x27;#30FBAB&#x27;</span>
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis, theme } = qcharts
theme.set({ <span class="hljs-attr">colors</span>: [color] })

<span class="hljs-keyword">const</span> { Gradient } = spritejs
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;value&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;label&#x27;</span>
})

<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({ <span class="hljs-attr">barWidth</span>: BAR_WIDTH })
  .style(<span class="hljs-string">&#x27;text&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">const</span> size = attrs.barAttrs.size
    <span class="hljs-keyword">const</span> points = attrs.barAttrs.points
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">padding</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">text</span>: data.value,
      <span class="hljs-attr">fillColor</span>: color,
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0.5</span>, <span class="hljs-number">1</span>],
      <span class="hljs-attr">pos</span>: [(points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] + points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>]) / <span class="hljs-number">2</span>, points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] - <span class="hljs-number">20</span>]
    }
  })
  .style(<span class="hljs-string">&#x27;pillar&#x27;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">const</span> points = attrs.points
    points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] = points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] + BAR_WIDTH / <span class="hljs-number">2</span>
    <span class="hljs-keyword">return</span> { points }
  })
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-params">d</span> =&gt;</span> <span class="hljs-string">`<span class="hljs-subst">${d.label}</span>: <span class="hljs-subst">${d.value}</span>`</span>
})
<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({ <span class="hljs-attr">orient</span>: <span class="hljs-string">&#x27;left&#x27;</span> })
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,0.5)&#x27;</span> })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, { <span class="hljs-attr">fillColor</span>: color })
<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis()
  .style(<span class="hljs-string">&#x27;scale&#x27;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&#x27;axis&#x27;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&#x27;rgba(48, 251, 171,1)&#x27;</span> })
  .style(<span class="hljs-string">&#x27;label&#x27;</span>, { <span class="hljs-attr">padding</span>: [<span class="hljs-number">20</span>, <span class="hljs-number">5</span>], <span class="hljs-attr">fillColor</span>: color })
  .style(<span class="hljs-string">&#x27;grid&#x27;</span>, <span class="hljs-literal">false</span>)

chart.append([bar, tooltip, axisBottom, axisLeft])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>