var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("自定义颜色极坐标柱状图")]),
      _vm._v(" "),
      _c(
        "block-demo",
        {
          attrs: {
            tip: "",
            source:
              "const data = [\n  {\n    product: '05-08',\n    year: '图例一',\n    sales: 5\n  },\n  {\n    product: '05-08',\n    year: '图例二',\n    sales: 15\n  },\n\n  {\n    product: '05-09',\n    year: '图例一',\n    sales: 10\n  },\n  {\n    product: '05-09',\n    year: '图例二',\n    sales: 25\n  },\n\n  {\n    product: '05-10',\n    year: '图例一',\n    sales: 20\n  },\n  {\n    product: '05-10',\n    year: '图例二',\n    sales: 30\n  },\n  {\n    product: '05-11',\n    year: '图例一',\n    sales: 30\n  },\n  {\n    product: '05-11',\n    year: '图例二',\n    sales: 30\n  },\n  {\n    product: '05-12',\n    year: '图例一',\n    sales: 35\n  },\n  {\n    product: '05-12',\n    year: '图例二',\n    sales: 35\n  }\n]\nconst { Chart, PolarBar, Tooltip, Axis, Legend } = qcharts\nconst chart = new Chart({\n  container: '#app'\n})\nchart.source(data, {\n  row: 'year',\n  value: 'sales',\n  text: 'product'\n})\nconst colors = ['#FF0000', '#F28F0F', '#FFFF00', '#00FF00', '#44256F']\nconst bar = new PolarBar({\n  stack: true,\n  radius: 0.7,\n  innerRadius: 0.2\n}).style('pillar', (attr, data, i) => {\n  if (i % 2 !== 0) {\n    return { strokeColor: '#fff', lineWidth: 1, fillColor: '#226464' }\n  } else {\n    return {\n      strokeColor: '#fff',\n      lineWidth: 1,\n      fillColor: colors[Math.floor(i / 2)]\n    }\n  }\n})\nconst tooltip = new Tooltip({\n  formatter: (d) => `${d.product} - ${d.year} - ${d.sales}`\n}).style('icon', (attr, data, i) => {\n  if (i % 2 !== 0) {\n    return { strokeColor: '#fff', lineWidth: 1, fillColor: '#226464' }\n  } else {\n    return {\n      strokeColor: '#fff',\n      lineWidth: 1,\n      fillColor: colors[Math.floor(i / 2)]\n    }\n  }\n})\nchart.append([bar, tooltip])\n"
          }
        },
        [
          _c("pre", { pre: true }, [
            _c(
              "code",
              {
                pre: true,
                attrs: { "v-pre": "", class: "language-javascript" }
              },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" data = [\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-08'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-08'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例二'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("15")
                ]),
                _vm._v("\n  },\n\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-09'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-09'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例二'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("25")
                ]),
                _vm._v("\n  },\n\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-10'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("20")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-10'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例二'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("30")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-11'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("30")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-11'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例二'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("30")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-12'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例一'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("35")
                ]),
                _vm._v("\n  },\n  {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("product")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'05-12'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("year")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'图例二'")
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("sales")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("35")
                ]),
                _vm._v("\n  }\n]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(
                  " { Chart, PolarBar, Tooltip, Axis, Legend } = qcharts\n"
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" chart = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Chart({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("container")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#app'")
                ]),
                _vm._v("\n})\nchart.source(data, {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("row")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'year'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("value")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'sales'")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("text")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'product'")
                ]),
                _vm._v("\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" colors = ["),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#FF0000'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#F28F0F'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#FFFF00'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#00FF00'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#44256F'")
                ]),
                _vm._v("]\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" bar = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" PolarBar({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("stack")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("radius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.7")
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("innerRadius")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.2")
                ]),
                _vm._v("\n}).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'pillar'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attr, data, i")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (i % "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(" !== "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(") {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#fff'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("lineWidth")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#226464'")
                ]),
                _vm._v(" }\n  } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#fff'")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("lineWidth")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": colors["),
                _c("span", { pre: true, attrs: { class: "hljs-built_in" } }, [
                  _vm._v("Math")
                ]),
                _vm._v(".floor(i / "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(")]\n    }\n  }\n})\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("const")
                ]),
                _vm._v(" tooltip = "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" Tooltip({\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("formatter")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("d")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("`"),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.product}")
                  ]),
                  _vm._v(" - "),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.year}")
                  ]),
                  _vm._v(" - "),
                  _c("span", { pre: true, attrs: { class: "hljs-subst" } }, [
                    _vm._v("${d.sales}")
                  ]),
                  _vm._v("`")
                ]),
                _vm._v("\n}).style("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'icon'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-function" } }, [
                  _vm._v("("),
                  _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                    _vm._v("attr, data, i")
                  ]),
                  _vm._v(") =>")
                ]),
                _vm._v(" {\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (i % "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(" !== "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(") {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" { "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#fff'")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("lineWidth")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#226464'")
                ]),
                _vm._v(" }\n  } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("strokeColor")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("'#fff'")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("lineWidth")
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("fillColor")
                ]),
                _vm._v(": colors["),
                _c("span", { pre: true, attrs: { class: "hljs-built_in" } }, [
                  _vm._v("Math")
                ]),
                _vm._v(".floor(i / "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(")]\n    }\n  }\n})\nchart.append([bar, tooltip])\n")
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }