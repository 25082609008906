<template><section><h2>折线图与柱状图组合</h2>
<block-demo tip="" source="const data = [
  { date: &quot;1月&quot;, catgory: &quot;降水量&quot;, val: 15.2 },
  { date: &quot;2月&quot;, catgory: &quot;降水量&quot;, val: 19.2 },
  { date: &quot;3月&quot;, catgory: &quot;降水量&quot;, val: 11.2 },
  { date: &quot;4月&quot;, catgory: &quot;降水量&quot;, val: 45.2 },
  { date: &quot;5月&quot;, catgory: &quot;降水量&quot;, val: 55.2 },
  { date: &quot;6月&quot;, catgory: &quot;降水量&quot;, val: 75.2 },
  { date: &quot;7月&quot;, catgory: &quot;降水量&quot;, val: 95.2 },
  { date: &quot;8月&quot;, catgory: &quot;降水量&quot;, val: 135.2 },
  { date: &quot;9月&quot;, catgory: &quot;降水量&quot;, val: 162.2 },
  { date: &quot;10月&quot;, catgory: &quot;降水量&quot;, val: 32.2 },
  { date: &quot;11月&quot;, catgory: &quot;降水量&quot;, val: 32.2 },
  { date: &quot;12月&quot;, catgory: &quot;降水量&quot;, val: 15.2 },

  { date: &quot;1月&quot;, catgory: &quot;气温&quot;, val: 2.2 },
  { date: &quot;2月&quot;, catgory: &quot;气温&quot;, val: 3.2 },
  { date: &quot;3月&quot;, catgory: &quot;气温&quot;, val: 5.2 },
  { date: &quot;4月&quot;, catgory: &quot;气温&quot;, val: 6.2 },
  { date: &quot;5月&quot;, catgory: &quot;气温&quot;, val: 8.2 },
  { date: &quot;6月&quot;, catgory: &quot;气温&quot;, val: 15.2 },
  { date: &quot;7月&quot;, catgory: &quot;气温&quot;, val: 25.2 },
  { date: &quot;8月&quot;, catgory: &quot;气温&quot;, val: 23.2 },
  { date: &quot;9月&quot;, catgory: &quot;气温&quot;, val: 24.2 },
  { date: &quot;10月&quot;, catgory: &quot;气温&quot;, val: 16.2 },
  { date: &quot;11月&quot;, catgory: &quot;气温&quot;, val: 12.2 },
  { date: &quot;12月&quot;, catgory: &quot;气温&quot;, val: 6.6 },
]

const { Chart, Line, Bar, Legend, Axis } = qcharts

const chart = new Chart({
  container: &quot;#app&quot;,
})

chart.source(data, {
  row: &quot;catgory&quot;,
  value: &quot;val&quot;,
  text: &quot;date&quot;,
})

const ds = chart.dataset

const d1 = ds.selectRows(&quot;降水量&quot;)
const line = new Line({ axisGap: true })
  .source(d1)
  .style(&quot;point&quot;, { strokeColor: &quot;#fff&quot; })

const axisLeft = new Axis({
  orient: &quot;left&quot;,
  formatter: (val) =&gt; {
    return `${val} ml`
  },
})
  .style(&quot;axis&quot;, false)
  .style(&quot;scale&quot;, false)
  .source(d1)

const d2 = ds.selectRows(&quot;气温&quot;)
const bar = new Bar().source(d2)
bar.style(&quot;pillar&quot;, { fillColor: &quot;#6CD3FF&quot; })

const axisRight = new Axis({
  orient: &quot;right&quot;,
  formatter: (val) =&gt; {
    return `${val} °C`
  },
})
  .style(&quot;axis&quot;, false)
  .style(&quot;scale&quot;, false)
  .source(d2)

const axisBottom = new Axis().style(&quot;scale&quot;, true).style(&quot;grid&quot;, false)

const legend = new Legend({ align: [&quot;center&quot;, &quot;bottom&quot;] })
  .style(&quot;point&quot;, (i, j, k) =&gt; {
    return { borderRadius: 10, bgcolor: k === 0 ? &quot;#47A1FF&quot; : &quot;#6CD3FF&quot; }
  })
  .style(&quot;text&quot;, { fontSize: 12 })

chart.append([bar, line, axisBottom, axisLeft, axisRight, legend])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;1月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;2月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">19.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;3月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">11.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;4月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">45.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;5月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;6月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;7月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;8月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">135.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;9月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">162.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;10月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;11月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">32.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;12月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;降水量&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },

  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;1月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">2.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;2月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">3.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;3月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">5.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;4月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;5月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">8.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;6月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;7月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">25.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;8月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">23.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;9月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">24.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;10月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">16.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;11月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">12.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&quot;12月&quot;</span>, <span class="hljs-attr">catgory</span>: <span class="hljs-string">&quot;气温&quot;</span>, <span class="hljs-attr">val</span>: <span class="hljs-number">6.6</span> },
]

<span class="hljs-keyword">const</span> { Chart, Line, Bar, Legend, Axis } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&quot;catgory&quot;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;val&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;date&quot;</span>,
})

<span class="hljs-keyword">const</span> ds = chart.dataset

<span class="hljs-keyword">const</span> d1 = ds.selectRows(<span class="hljs-string">&quot;降水量&quot;</span>)
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({ <span class="hljs-attr">axisGap</span>: <span class="hljs-literal">true</span> })
  .source(d1)
  .style(<span class="hljs-string">&quot;point&quot;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&quot;#fff&quot;</span> })

<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&quot;left&quot;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">val</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${val}</span> ml`</span>
  },
})
  .style(<span class="hljs-string">&quot;axis&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .source(d1)

<span class="hljs-keyword">const</span> d2 = ds.selectRows(<span class="hljs-string">&quot;气温&quot;</span>)
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar().source(d2)
bar.style(<span class="hljs-string">&quot;pillar&quot;</span>, { <span class="hljs-attr">fillColor</span>: <span class="hljs-string">&quot;#6CD3FF&quot;</span> })

<span class="hljs-keyword">const</span> axisRight = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&quot;right&quot;</span>,
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">val</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${val}</span> °C`</span>
  },
})
  .style(<span class="hljs-string">&quot;axis&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .source(d2)

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis().style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">true</span>).style(<span class="hljs-string">&quot;grid&quot;</span>, <span class="hljs-literal">false</span>)

<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&quot;center&quot;</span>, <span class="hljs-string">&quot;bottom&quot;</span>] })
  .style(<span class="hljs-string">&quot;point&quot;</span>, <span class="hljs-function">(<span class="hljs-params">i, j, k</span>) =&gt;</span> {
    <span class="hljs-keyword">return</span> { <span class="hljs-attr">borderRadius</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">bgcolor</span>: k === <span class="hljs-number">0</span> ? <span class="hljs-string">&quot;#47A1FF&quot;</span> : <span class="hljs-string">&quot;#6CD3FF&quot;</span> }
  })
  .style(<span class="hljs-string">&quot;text&quot;</span>, { <span class="hljs-attr">fontSize</span>: <span class="hljs-number">12</span> })

chart.append([bar, line, axisBottom, axisLeft, axisRight, legend])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>