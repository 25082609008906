<template><section><h2>柱状图颜色渐变</h2>
<block-demo tip="" source="const data = [
  { value: 3350, label: &quot;一般&quot; },
  { value: 1548, label: &quot;较大&quot; },
  { value: 2340, label: &quot;重大&quot; },
  { value: 3000, label: &quot;特大&quot; },
]

const { Chart, Bar, Tooltip, Axis } = qcharts

const { Gradient } = spritejs
const chart = new Chart({
  container: &quot;#app&quot;,
})

chart.source(data, {
  value: &quot;value&quot;,
  text: &quot;label&quot;,
})

const bar = new Bar({ barWidth: 12 })
  .style(&quot;pillar&quot;, (attrs, d, i) =&gt; {
    let size = attrs.size
    if (i === 0) {
      return {
        fillColor: new Gradient({
          vector: [0, 0, size[0], size[1]],
          colors: [
            { color: &quot;#007BF1&quot;, offset: 0 },
            { color: &quot;#0046DF&quot;, offset: 1 },
          ],
        }),
      }
    } else if (i === 1) {
      return {
        fillColor: new Gradient({
          vector: [0, 0, size[0], size[1]],
          colors: [
            { color: &quot;#BC9621&quot;, offset: 0 },
            { color: &quot;#D58B00&quot;, offset: 1 },
          ],
        }),
      }
    } else if (i === 2) {
      return {
        fillColor: new Gradient({
          vector: [0, 0, size[0], size[1]],
          colors: [
            { color: &quot;#E44E00&quot;, offset: 0 },
            { color: &quot;#DF4900&quot;, offset: 1 },
          ],
        }),
      }
    } else if (i === 3) {
      return {
        fillColor: new Gradient({
          vector: [0, 0, size[0], size[1]],
          colors: [
            { color: &quot;#F10000&quot;, offset: 0 },
            { color: &quot;#9A0000&quot;, offset: 1 },
          ],
        }),
      }
    }
  })
  .style(&quot;text&quot;, (attrs, data, i) =&gt; {
    const size = attrs.barAttrs.size
    const points = attrs.barAttrs.points
    return {
      padding: 0,
      rotate: 0,
      text: &quot;158&quot;,
      anchor: [0.5, 1],
      pos: [(points[0][0] + points[1][0]) / 2, points[0][1] - 10],
    }
  })

const tooltip = new Tooltip({
  formatter: (d) =&gt; `${d.label}: ${d.value}`,
})

const axisBottom = new Axis()
  .style(&quot;scale&quot;, false)
  .style(&quot;axis&quot;, false)
  .style(&quot;label&quot;, { padding: [20, 5] })

chart.append([bar, tooltip, axisBottom])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">value</span>: <span class="hljs-number">3350</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;一般&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">1548</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;较大&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">2340</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;重大&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">3000</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;特大&quot;</span> },
]

<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis } = qcharts

<span class="hljs-keyword">const</span> { Gradient } = spritejs
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})

chart.source(data, {
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;value&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;label&quot;</span>,
})

<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({ <span class="hljs-attr">barWidth</span>: <span class="hljs-number">12</span> })
  .style(<span class="hljs-string">&quot;pillar&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, d, i</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> size = attrs.size
    <span class="hljs-keyword">if</span> (i === <span class="hljs-number">0</span>) {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fillColor</span>: <span class="hljs-keyword">new</span> Gradient({
          <span class="hljs-attr">vector</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, size[<span class="hljs-number">0</span>], size[<span class="hljs-number">1</span>]],
          <span class="hljs-attr">colors</span>: [
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#007BF1&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">0</span> },
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#0046DF&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">1</span> },
          ],
        }),
      }
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (i === <span class="hljs-number">1</span>) {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fillColor</span>: <span class="hljs-keyword">new</span> Gradient({
          <span class="hljs-attr">vector</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, size[<span class="hljs-number">0</span>], size[<span class="hljs-number">1</span>]],
          <span class="hljs-attr">colors</span>: [
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#BC9621&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">0</span> },
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#D58B00&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">1</span> },
          ],
        }),
      }
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (i === <span class="hljs-number">2</span>) {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fillColor</span>: <span class="hljs-keyword">new</span> Gradient({
          <span class="hljs-attr">vector</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, size[<span class="hljs-number">0</span>], size[<span class="hljs-number">1</span>]],
          <span class="hljs-attr">colors</span>: [
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#E44E00&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">0</span> },
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#DF4900&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">1</span> },
          ],
        }),
      }
    } <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (i === <span class="hljs-number">3</span>) {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fillColor</span>: <span class="hljs-keyword">new</span> Gradient({
          <span class="hljs-attr">vector</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, size[<span class="hljs-number">0</span>], size[<span class="hljs-number">1</span>]],
          <span class="hljs-attr">colors</span>: [
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#F10000&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">0</span> },
            { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#9A0000&quot;</span>, <span class="hljs-attr">offset</span>: <span class="hljs-number">1</span> },
          ],
        }),
      }
    }
  })
  .style(<span class="hljs-string">&quot;text&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i</span>) =&gt;</span> {
    <span class="hljs-keyword">const</span> size = attrs.barAttrs.size
    <span class="hljs-keyword">const</span> points = attrs.barAttrs.points
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">padding</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;158&quot;</span>,
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0.5</span>, <span class="hljs-number">1</span>],
      <span class="hljs-attr">pos</span>: [(points[<span class="hljs-number">0</span>][<span class="hljs-number">0</span>] + points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>]) / <span class="hljs-number">2</span>, points[<span class="hljs-number">0</span>][<span class="hljs-number">1</span>] - <span class="hljs-number">10</span>],
    }
  })

<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">`<span class="hljs-subst">${d.label}</span>: <span class="hljs-subst">${d.value}</span>`</span>,
})

<span class="hljs-keyword">const</span> axisBottom = <span class="hljs-keyword">new</span> Axis()
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;axis&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;label&quot;</span>, { <span class="hljs-attr">padding</span>: [<span class="hljs-number">20</span>, <span class="hljs-number">5</span>] })

chart.append([bar, tooltip, axisBottom])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>