var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-box" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query"
          }
        ],
        attrs: { type: "text", placeholder: "搜索组件.." },
        domProps: { value: _vm.query },
        on: {
          blur: _vm.reset,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.go(null)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.query = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.suggestions.length > 0,
                expression: "suggestions.length > 0"
              }
            ],
            staticClass: "suggestions",
            on: { mouseleave: _vm.blur }
          },
          _vm._l(_vm.suggestions, function(s, i) {
            return _c(
              "li",
              {
                key: "suggestion" + i,
                on: {
                  mouseenter: function($event) {
                    return _vm.focus(i)
                  },
                  mousedown: function($event) {
                    return _vm.go(i)
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: s.path },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v(_vm._s(s.title))]
                )
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }