var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "block",
      class: ["block-demo", _vm.isFullscreen ? "block-demo--fixed" : ""]
    },
    [
      _c("div", { ref: "preview", staticClass: "preview" }, [
        _c("div", { ref: "demo", staticClass: "demo" })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEditor,
              expression: "showEditor"
            }
          ],
          ref: "editor",
          staticClass: "editor"
        },
        [
          _c("div", { staticClass: "bock-demo__ctrl" }, [
            _c(
              "span",
              {
                staticClass: "icon",
                attrs: { title: "运行" },
                on: { click: _vm.syncCode }
              },
              [
                _c("img", {
                  attrs: { src: require("./play.svg"), alt: "运行" }
                })
              ]
            ),
            _vm._v(" "),
            !_vm.isFullscreen
              ? _c(
                  "span",
                  {
                    staticClass: "icon",
                    attrs: { title: "全屏" },
                    on: { click: _vm.fullscreen }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./full-screen.svg"), alt: "全屏" }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFullscreen
              ? _c(
                  "span",
                  {
                    staticClass: "icon",
                    attrs: { title: "取消全屏" },
                    on: { click: _vm.fullscreen }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("./recovery.svg"), alt: "取消全屏" }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "icon", on: { click: _vm.copyCode } }, [
              _c("img", {
                attrs: { src: require("./code-copy.svg"), alt: "复制代码" }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bock-demo__code" }, [
            _c("textarea", { ref: "textarea" })
          ])
        ]
      ),
      _vm._v(" "),
      _c("textarea", { ref: "copytxt", staticClass: "copytxt" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }