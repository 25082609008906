<template><section><h2>Double Line Chart 双 Y 轴折线图</h2>
<block-demo tip="" source="const data = [
  { date: &#39;05-01&#39;, category: &#39;图例一&#39;, sales: 15.2 },
  { date: &#39;05-02&#39;, category: &#39;图例一&#39;, sales: 39.2 },
  { date: &#39;05-03&#39;, category: &#39;图例一&#39;, sales: 51.2 },
  { date: &#39;05-04&#39;, category: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-05&#39;, category: &#39;图例一&#39;, sales: 55.2 },
  { date: &#39;05-06&#39;, category: &#39;图例一&#39;, sales: 75.2 },
  { date: &#39;05-07&#39;, category: &#39;图例一&#39;, sales: 85.2 },
  { date: &#39;05-08&#39;, category: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-01&#39;, category: &#39;图例二&#39;, sales: 20.2 },
  { date: &#39;05-02&#39;, category: &#39;图例二&#39;, sales: 49.2 },
  { date: &#39;05-03&#39;, category: &#39;图例二&#39;, sales: 57.2 },
  { date: &#39;05-04&#39;, category: &#39;图例二&#39;, sales: 55.2 },
  { date: &#39;05-05&#39;, category: &#39;图例二&#39;, sales: 75.2 },
  { date: &#39;05-06&#39;, category: &#39;图例二&#39;, sales: 95.2 },
  { date: &#39;05-07&#39;, category: &#39;图例二&#39;, sales: 90.2 },
  { date: &#39;05-08&#39;, category: &#39;图例二&#39;, sales: 85.2 }
]

const data1 = [
  { date: &#39;05-01&#39;, category: &#39;图例一&#39;, sales: 25.2 },
  { date: &#39;05-02&#39;, category: &#39;图例一&#39;, sales: 19.2 },
  { date: &#39;05-03&#39;, category: &#39;图例一&#39;, sales: 41.2 },
  { date: &#39;05-04&#39;, category: &#39;图例一&#39;, sales: 75.2 },
  { date: &#39;05-05&#39;, category: &#39;图例一&#39;, sales: 35.2 },
  { date: &#39;05-06&#39;, category: &#39;图例一&#39;, sales: 65.2 },
  { date: &#39;05-07&#39;, category: &#39;图例一&#39;, sales: 95.2 },
  { date: &#39;05-08&#39;, category: &#39;图例一&#39;, sales: 45.2 },
  { date: &#39;05-01&#39;, category: &#39;图例二&#39;, sales: 70.2 },
  { date: &#39;05-02&#39;, category: &#39;图例二&#39;, sales: 79.2 },
  { date: &#39;05-03&#39;, category: &#39;图例二&#39;, sales: 47.2 },
  { date: &#39;05-04&#39;, category: &#39;图例二&#39;, sales: 85.2 },
  { date: &#39;05-05&#39;, category: &#39;图例二&#39;, sales: 95.2 },
  { date: &#39;05-06&#39;, category: &#39;图例二&#39;, sales: 65.2 },
  { date: &#39;05-07&#39;, category: &#39;图例二&#39;, sales: 40.2 },
  { date: &#39;05-08&#39;, category: &#39;图例二&#39;, sales: 55.2 }
]

const { Chart, Line } = qcharts

const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;category&#39;,
  value: &#39;sales&#39;,
  text: &#39;date&#39;
})
let newData = chart.dataset.selectRows([&#39;图例一&#39;])
// console.log(newData)
const line = new Line({ smooth: true, splitNumber: 5 })
chart.dataset.on(&#39;change&#39;, function(res) {
  console.log(&#39;dataset&#39;, res)
})
newData.on(&#39;change&#39;, function(res) {
  console.log(&#39;newData&#39;, res)
})

chart.append([line])
setTimeout(function() {
  //chart.source(data1)
}, 1000)
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">15.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">39.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">51.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">85.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">20.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">49.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">57.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">90.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">85.2</span> }
]

<span class="hljs-keyword">const</span> data1 = [
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">25.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">19.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">41.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">75.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">35.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例一&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">45.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-01&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">70.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-02&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">79.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-03&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">47.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-04&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">85.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-05&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">95.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-06&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">65.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-07&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">40.2</span> },
  { <span class="hljs-attr">date</span>: <span class="hljs-string">&#x27;05-08&#x27;</span>, <span class="hljs-attr">category</span>: <span class="hljs-string">&#x27;图例二&#x27;</span>, <span class="hljs-attr">sales</span>: <span class="hljs-number">55.2</span> }
]

<span class="hljs-keyword">const</span> { Chart, Line } = qcharts

<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;category&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&#x27;date&#x27;</span>
})
<span class="hljs-keyword">let</span> newData = chart.dataset.selectRows([<span class="hljs-string">&#x27;图例一&#x27;</span>])
<span class="hljs-comment">// console.log(newData)</span>
<span class="hljs-keyword">const</span> line = <span class="hljs-keyword">new</span> Line({ <span class="hljs-attr">smooth</span>: <span class="hljs-literal">true</span>, <span class="hljs-attr">splitNumber</span>: <span class="hljs-number">5</span> })
chart.dataset.on(<span class="hljs-string">&#x27;change&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">res</span>) </span>{
  <span class="hljs-built_in">console</span>.log(<span class="hljs-string">&#x27;dataset&#x27;</span>, res)
})
newData.on(<span class="hljs-string">&#x27;change&#x27;</span>, <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">res</span>) </span>{
  <span class="hljs-built_in">console</span>.log(<span class="hljs-string">&#x27;newData&#x27;</span>, res)
})

chart.append([line])
<span class="hljs-built_in">setTimeout</span>(<span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params"></span>) </span>{
  <span class="hljs-comment">//chart.source(data1)</span>
}, <span class="hljs-number">1000</span>)
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>