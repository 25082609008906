<template><section><h2>Donut Chart 动态环图</h2>
<block-demo tip="" source="const data = [
  {
    year: &#39;2016&#39;,
    sales: 17,
    selected: true
  },
  {
    year: &#39;2017&#39;,
    sales: 28
  },
  {
    year: &#39;2018&#39;,
    sales: 25
  },
  {
    year: &#39;2019&#39;,
    sales: 7
  },
  {
    year: &#39;2020&#39;,
    sales: 22
  }
]
const length = data.length
let counter = 0
const { Chart, Pie } = qcharts
const { Label } = spritejs
const chart = new Chart({
  container: &#39;#app&#39;
})

chart.source(data, {
  row: &#39;year&#39;,
  value: &#39;sales&#39;
})

const pie = new Pie({
  innerRadius: 0.4,
  radius: 0.5,
  animation: { duration: 400 },
  formatter: function(str) {
    return `${str} %`
  }
})

chart.append([pie])

setInterval(changeData, 3000)
//数据被选中动画模拟，轮流设置数据的selected属性，同时移除上一个数据的selected属性
function changeData() {
  if (counter &lt; 4) {
    counter++
  } else {
    counter = 0
  }
  pie.dataset.forEach((item, ind) =&gt; {
    if (item.state === &#39;hover&#39; &amp;&amp; ind !== counter) {
      item.state = &#39;default&#39;
    }
  })
  pie.dataset[counter].state = &#39;hover&#39;
}
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  {
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;2016&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">17</span>,
    <span class="hljs-attr">selected</span>: <span class="hljs-literal">true</span>
  },
  {
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;2017&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">28</span>
  },
  {
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;2018&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">25</span>
  },
  {
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;2019&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">7</span>
  },
  {
    <span class="hljs-attr">year</span>: <span class="hljs-string">&#x27;2020&#x27;</span>,
    <span class="hljs-attr">sales</span>: <span class="hljs-number">22</span>
  }
]
<span class="hljs-keyword">const</span> length = data.length
<span class="hljs-keyword">let</span> counter = <span class="hljs-number">0</span>
<span class="hljs-keyword">const</span> { Chart, Pie } = qcharts
<span class="hljs-keyword">const</span> { Label } = spritejs
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&#x27;#app&#x27;</span>
})

chart.source(data, {
  <span class="hljs-attr">row</span>: <span class="hljs-string">&#x27;year&#x27;</span>,
  <span class="hljs-attr">value</span>: <span class="hljs-string">&#x27;sales&#x27;</span>
})

<span class="hljs-keyword">const</span> pie = <span class="hljs-keyword">new</span> Pie({
  <span class="hljs-attr">innerRadius</span>: <span class="hljs-number">0.4</span>,
  <span class="hljs-attr">radius</span>: <span class="hljs-number">0.5</span>,
  <span class="hljs-attr">animation</span>: { <span class="hljs-attr">duration</span>: <span class="hljs-number">400</span> },
  <span class="hljs-attr">formatter</span>: <span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params">str</span>) </span>{
    <span class="hljs-keyword">return</span> <span class="hljs-string">`<span class="hljs-subst">${str}</span> %`</span>
  }
})

chart.append([pie])

<span class="hljs-built_in">setInterval</span>(changeData, <span class="hljs-number">3000</span>)
<span class="hljs-comment">//数据被选中动画模拟，轮流设置数据的selected属性，同时移除上一个数据的selected属性</span>
<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">changeData</span>(<span class="hljs-params"></span>) </span>{
  <span class="hljs-keyword">if</span> (counter &lt; <span class="hljs-number">4</span>) {
    counter++
  } <span class="hljs-keyword">else</span> {
    counter = <span class="hljs-number">0</span>
  }
  pie.dataset.forEach(<span class="hljs-function">(<span class="hljs-params">item, ind</span>) =&gt;</span> {
    <span class="hljs-keyword">if</span> (item.state === <span class="hljs-string">&#x27;hover&#x27;</span> &amp;&amp; ind !== counter) {
      item.state = <span class="hljs-string">&#x27;default&#x27;</span>
    }
  })
  pie.dataset[counter].state = <span class="hljs-string">&#x27;hover&#x27;</span>
}
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>