<template><section><h2>纹理条形图</h2>
<block-demo tip="" source="const data = [
  { value: 43, label: &quot;TOP1&quot; },
  { value: 26, label: &quot;TOP2&quot; },
  { value: 24, label: &quot;TOP3&quot; },
  { value: 20, label: &quot;TOP4&quot; },
  { value: 10, label: &quot;TOP5&quot; },
]
const { Chart, Bar, Tooltip, Axis, Legend } = qcharts
const chart = new Chart({
  container: &quot;#app&quot;,
})
chart.source(data, {
  value: &quot;value&quot;,
  text: &quot;label&quot;,
})
const bar = new Bar({
  transpose: true,
  barWidth: 30,
})

const texture1 = &quot;https://p3.ssl.qhimg.com/t01128a717c0ff244f1.png&quot;
const texture2 = &quot;https://p5.ssl.qhimg.com/t01c5d76eabda023606.png&quot;
bar
  .style(&quot;pillar&quot;, (attrs, data, i, j) =&gt; {
    if (i === 0) {
      return {
        texture: texture1,
        textureRect: [0, 0, 445, 40],
      }
    } else {
      return {
        texture: texture2,
        textureRect: [0, 0, 445, 40],
      }
    }
  })
  .style(&quot;text&quot;, (attrs, data, i, j) =&gt; {
    let size = attrs.barAttrs.size
    let points = attrs.barAttrs.points
    return {
      color: &quot;#1DCE91&quot;,
      rotate: 0,
      text: data.value,
      anchor: [0, 0.5],
      pos: [points[1][0] + 5, (points[1][1] + points[2][1]) / 2],
    }
  })
const tooltip = new Tooltip({
  formatter: (d) =&gt; `${d.label}: ${d.value}`,
})
const legend = new Legend({ align: [&quot;center&quot;, &quot;bottom&quot;] }).style(&quot;text&quot;, {
  text: &quot;图例一&quot;,
})

const axisLeft = new Axis({
  orient: &quot;left&quot;,
})
  .style(&quot;axis&quot;, { strokeColor: &quot;#1DCE91&quot; })
  .style(&quot;grid&quot;, false)
  .style(&quot;scale&quot;, false)
  .style(&quot;label&quot;, { color: &quot;#1DCE91&quot; })
chart.append([bar, axisLeft])
"><pre v-pre><code v-pre class="language-javascript"><span class="hljs-keyword">const</span> data = [
  { <span class="hljs-attr">value</span>: <span class="hljs-number">43</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;TOP1&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">26</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;TOP2&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">24</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;TOP3&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">20</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;TOP4&quot;</span> },
  { <span class="hljs-attr">value</span>: <span class="hljs-number">10</span>, <span class="hljs-attr">label</span>: <span class="hljs-string">&quot;TOP5&quot;</span> },
]
<span class="hljs-keyword">const</span> { Chart, Bar, Tooltip, Axis, Legend } = qcharts
<span class="hljs-keyword">const</span> chart = <span class="hljs-keyword">new</span> Chart({
  <span class="hljs-attr">container</span>: <span class="hljs-string">&quot;#app&quot;</span>,
})
chart.source(data, {
  <span class="hljs-attr">value</span>: <span class="hljs-string">&quot;value&quot;</span>,
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;label&quot;</span>,
})
<span class="hljs-keyword">const</span> bar = <span class="hljs-keyword">new</span> Bar({
  <span class="hljs-attr">transpose</span>: <span class="hljs-literal">true</span>,
  <span class="hljs-attr">barWidth</span>: <span class="hljs-number">30</span>,
})

<span class="hljs-keyword">const</span> texture1 = <span class="hljs-string">&quot;https://p3.ssl.qhimg.com/t01128a717c0ff244f1.png&quot;</span>
<span class="hljs-keyword">const</span> texture2 = <span class="hljs-string">&quot;https://p5.ssl.qhimg.com/t01c5d76eabda023606.png&quot;</span>
bar
  .style(<span class="hljs-string">&quot;pillar&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">if</span> (i === <span class="hljs-number">0</span>) {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">texture</span>: texture1,
        <span class="hljs-attr">textureRect</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, <span class="hljs-number">445</span>, <span class="hljs-number">40</span>],
      }
    } <span class="hljs-keyword">else</span> {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">texture</span>: texture2,
        <span class="hljs-attr">textureRect</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0</span>, <span class="hljs-number">445</span>, <span class="hljs-number">40</span>],
      }
    }
  })
  .style(<span class="hljs-string">&quot;text&quot;</span>, <span class="hljs-function">(<span class="hljs-params">attrs, data, i, j</span>) =&gt;</span> {
    <span class="hljs-keyword">let</span> size = attrs.barAttrs.size
    <span class="hljs-keyword">let</span> points = attrs.barAttrs.points
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span>,
      <span class="hljs-attr">rotate</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">text</span>: data.value,
      <span class="hljs-attr">anchor</span>: [<span class="hljs-number">0</span>, <span class="hljs-number">0.5</span>],
      <span class="hljs-attr">pos</span>: [points[<span class="hljs-number">1</span>][<span class="hljs-number">0</span>] + <span class="hljs-number">5</span>, (points[<span class="hljs-number">1</span>][<span class="hljs-number">1</span>] + points[<span class="hljs-number">2</span>][<span class="hljs-number">1</span>]) / <span class="hljs-number">2</span>],
    }
  })
<span class="hljs-keyword">const</span> tooltip = <span class="hljs-keyword">new</span> Tooltip({
  <span class="hljs-attr">formatter</span>: <span class="hljs-function">(<span class="hljs-params">d</span>) =&gt;</span> <span class="hljs-string">`<span class="hljs-subst">${d.label}</span>: <span class="hljs-subst">${d.value}</span>`</span>,
})
<span class="hljs-keyword">const</span> legend = <span class="hljs-keyword">new</span> Legend({ <span class="hljs-attr">align</span>: [<span class="hljs-string">&quot;center&quot;</span>, <span class="hljs-string">&quot;bottom&quot;</span>] }).style(<span class="hljs-string">&quot;text&quot;</span>, {
  <span class="hljs-attr">text</span>: <span class="hljs-string">&quot;图例一&quot;</span>,
})

<span class="hljs-keyword">const</span> axisLeft = <span class="hljs-keyword">new</span> Axis({
  <span class="hljs-attr">orient</span>: <span class="hljs-string">&quot;left&quot;</span>,
})
  .style(<span class="hljs-string">&quot;axis&quot;</span>, { <span class="hljs-attr">strokeColor</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span> })
  .style(<span class="hljs-string">&quot;grid&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;scale&quot;</span>, <span class="hljs-literal">false</span>)
  .style(<span class="hljs-string">&quot;label&quot;</span>, { <span class="hljs-attr">color</span>: <span class="hljs-string">&quot;#1DCE91&quot;</span> })
chart.append([bar, axisLeft])
</code></pre>
</block-demo></section></template><script>export default {components:{}}</script>